import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences";
import { FileSize } from "../components/FileUpload"

export class UIHelpers{
    public static getBytesFormat(bytes: number): FileSize {
        if (!bytes) return FileSize.BYTES;
    
        const k = 1024
        const sizes = [FileSize.BYTES, FileSize.KB, FileSize.MB, FileSize.GB];
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        
        if (i > sizes.length){
            return FileSize.GB;
        }
        return sizes[i] as FileSize;
    }
}

export type Preferences = CollectionPreferencesProps.Preferences<any>;