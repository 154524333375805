import { API_BASE_URL } from '../configuration/config';
import { StatusCodes } from "http-status-codes";

import { BaseService } from "./base-service";

/**
 * To obtain CSRF tokens from the backend
 */
export class CSRFService extends BaseService {
  constructor() {
    super(API_BASE_URL);
  }

  public async getToken(): Promise<string> {
    const response = await this.fetch("/csrf");
    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred getting CSRF token.");
    }
    const jsonResponse = await response.json();
    return jsonResponse.token as string;
  }
}
