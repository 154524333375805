import {
  Box,
  Button,
  FormField,
  Header,
  Modal,
  SpaceBetween,
  Textarea
} from '@amzn/awsui-components-react';
import { Inventory } from "@amzn/tinvent-typescript-client";
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { useEffect, useState } from 'react';
import { CSRFService } from 'services/csrf-service';
import { getString } from '../../../common/ui-string-labels/ui-strings-utils';
import { usePatchInventoryNote } from '../../../hooks';

export interface UpdateInventoryNoteModalProps {
  selectedItems: Inventory[];
  showModal: boolean;
  onDismiss: (shouldRefreshTable?: boolean) => void;
  flashBarItemsActions: useFlashBarItemsActions;
}

export const UpdateInventoryNoteModal = (props: UpdateInventoryNoteModalProps) => {
  const {selectedItems, flashBarItemsActions, onDismiss} = props;
  const [data, isProcessing, error, doPatchUpdateInventoryNotes] = usePatchInventoryNote(undefined);
  let defaultNote: string = "";

  if (props.selectedItems.length === 1 && (props.selectedItems[0] as Inventory).stampedNote) {
    defaultNote = (props.selectedItems[0] as Inventory).stampedNote?.note ?? "";
  }

  const [note, setNote] = useState(defaultNote!);

  /* istanbul ignore next */
  const updateNote = async () => {
    // Fetch CSRF token
    const csrfService = new CSRFService();
    csrfService.getToken().then(async (token: string) => {
      await doPatchUpdateInventoryNotes(selectedItems.map(i => i.productTaxonomyId!), token, note);
    });
  };

  /* istanbul ignore next */
  const dismissModal = () => {
    onDismiss(false);
  };

  /* istanbul ignore next */
  const onChange = ({ detail }: any) => {
    setNote(detail.value);
  };

  const numberOfItems = selectedItems.length > 1 ? ` (${selectedItems.length})` : "";

  const footerElement = (
    <SpaceBetween direction="horizontal" size="xs">
      <Button variant="link" onClick={dismissModal}>
        {getString('common.cancel')}
      </Button>
      <Button
        variant="primary"
        data-cy="update-note-action"
        onClick={updateNote}
        disabled={note.trim() === "" || isProcessing}
        loading={isProcessing}
      >
        {`${getString('common.update')}${numberOfItems}`}
      </Button>
    </SpaceBetween>
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (data) {
      onDismiss(true);
      flashBarItemsActions.addSuccessFlashBarItem(getString('manageLicense.updateInventoryNoteModal.success'));
    }
  }, [data, flashBarItemsActions, onDismiss]);

  /* istanbul ignore next */
  useEffect(() => {
    if (error && error.hasError) {
      onDismiss(false);
      flashBarItemsActions.addErrorFlashBarItem(`${getString('manageLicense.updateInventoryNoteModal.error')}. ${error.message}`);
    }
  }, [error, flashBarItemsActions, onDismiss]);

  return (
    <Modal
      data-testid="update-note-modal"
      onDismiss={dismissModal}
      visible={props.showModal}
      header={<Header variant="h2">{getString('manageLicense.updateInventoryNoteModal.modalTitle')}</Header>}
      footer={<Box float="right">{footerElement}</Box>}
    >
      <FormField label={getString('manageLicense.updateInventoryNoteModal.fieldLabel')}>
        <Textarea onChange={onChange} value={note} max-length={255} />
      </FormField>
    </Modal>
  );
};
