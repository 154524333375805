import {
    Alert,
    Box,
    Button,
    Modal,
    SpaceBetween
  } from '@amzn/awsui-components-react';
import { CancelableEventHandler, ClickDetail } from '@amzn/awsui-components-react/polaris/internal/events';
import { getString } from 'common/ui-string-labels/ui-strings-utils';

export interface ConfirmActionModalProps {
    visible: boolean;
    onDismiss: (shouldRefreshTable?: boolean) => void;
    onAction: CancelableEventHandler<ClickDetail> | undefined;
    modalTitle: string;
    warningText: string;
    informationalText?: string;
    isProcessing: boolean;
}

export const ConfirmActionModal = (props: ConfirmActionModalProps) => {
    const {visible, onDismiss, onAction, warningText, modalTitle, informationalText, isProcessing} = props;

    /* istanbul ignore next */
    const dismissModal = () => {
        onDismiss(false);
      };
    
    return <Modal
        data-testid="confirm-action-modal"
        visible={visible}
        onDismiss={dismissModal}
        header={modalTitle}
        closeAriaLabel={getString("common.confirmActionModal.closeAriaLabel")}
        footer={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={dismissModal}>
                        {getString("common.cancel")}
                    </Button>
                    <Button variant="primary" onClick={onAction} data-testid="submit" loading={isProcessing} disabled={isProcessing}>
                        {getString("common.confirm")}
                    </Button>
                </SpaceBetween>
            </Box>
        }
    >
        <SpaceBetween size="m">
            <Box variant="span">
                {warningText}
            </Box>
            {informationalText && <Alert statusIconAriaLabel="Info">{informationalText}</Alert> }
        </SpaceBetween>
    </Modal>
}