import { Box, Button, FormField, Header, Input, Modal, SpaceBetween, Spinner, Textarea } from '@amzn/awsui-components-react';
import { Order } from '@amzn/ito-client';
import { useEffect, useState } from 'react';
import { getString } from '../../../common/ui-string-labels/ui-strings-utils';
import { useProcureOrder } from '../../../hooks';
import { CatalogicalService } from '../../../services/catalogical-service';

import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { CATALOGICAL_EDIT_ITEM_URL } from 'configuration/config';
import { CSRFService } from 'services/csrf-service';

export interface CompleteOrderModalProps {
  selectedItem: Order;
  showModal: boolean;
  onDismiss: (shouldRefreshTable?: boolean) => void;
  flashBarItemsActions: useFlashBarItemsActions;
}

export const CompleteOrderModal = (props: CompleteOrderModalProps) => {
  const { selectedItem, flashBarItemsActions, onDismiss } = props;

  const [licenseName, setLicenseName] = useState<string>('');
  const [licenseKey, setLicenseKey] = useState<string>('');
  const [licenseInstructions, setLicenseInstructions] = useState<string>('');
  const [productDownloadUrl, setProductDownloadUrl] = useState<string>('');
  const [isLoadingCatalogInfo, setIsLoadingCatalogInfo] = useState<boolean>(false);
  const [itemEditLink, setItemEditLink] = useState<string>('');
  const [procureResponse, isProcessing, error, doProcureOrder] = useProcureOrder();

  /* istanbul ignore next */
  const dismissModal = () => {
    onDismiss(false);
  };

  /* istanbul ignore next */
  const onLicenseNameChange = ({ detail }: any) => {
    setLicenseName(detail.value);
  };

  /* istanbul ignore next */
  const onLicenseKeyChange = ({ detail }: any) => {
    setLicenseKey(detail.value);
  };

  /* istanbul ignore next */
  const onProductDownloadUrlChange = ({ detail }: any) => {
    setProductDownloadUrl(detail.value);
  };

  /* istanbul ignore next */
  const completeOrder = () => {
    // Fetch CSRF token
    const csrfService = new CSRFService();
    csrfService.getToken().then((token: string) => {
      doProcureOrder(selectedItem, licenseName, licenseKey, licenseInstructions, productDownloadUrl, token);
    });
  };

  /* istanbul ignore next */
  const shouldDisableFooter = () => {
    return licenseName.trim() === '' || licenseKey.trim() === '' || licenseInstructions.trim() === '' || productDownloadUrl.trim() === '';
  };

  const footerElement = (
    <SpaceBetween direction="horizontal" size="xs">
      <Button variant="link" onClick={dismissModal} disabled={isProcessing}>
        {getString('manageOrders.completeModal.cancelButton')}
      </Button>
      <Button
        variant="primary"
        loading={isProcessing}
        data-cy="complete-order-action"
        onClick={completeOrder}
        disabled={shouldDisableFooter()}
      >
        {getString('manageOrders.completeModal.okButton')}
      </Button>
    </SpaceBetween>
  );

  /* istanbul ignore next */
  useEffect(() => {
    (async () => {
      if (selectedItem?.items && selectedItem.items.length === 1) {
        setItemEditLink(CATALOGICAL_EDIT_ITEM_URL.replace("{ITEM_ID}", selectedItem.items[0].itemId!));

        setIsLoadingCatalogInfo(true);

        const catalogicalInfo = await CatalogicalService.instance.getCompleteOrderCatalogInformation(
          selectedItem.items[0].itemId!
        );

        setIsLoadingCatalogInfo(false);

        if (catalogicalInfo.licenseInstructions) {
          setLicenseInstructions(catalogicalInfo.licenseInstructions);
        }
        if (catalogicalInfo.productDownloadUrl) {
          setProductDownloadUrl(catalogicalInfo.productDownloadUrl);
        }
      }
    })();
  }, [selectedItem]);

  /* istanbul ignore next */
  useEffect(() => {
    if (procureResponse) {
      if (error) {
        flashBarItemsActions.addErrorFlashBarItem(`${getString('manageOrders.completeModal.error')}. ${error.message}`);
      } else {
        flashBarItemsActions.addSuccessFlashBarItem(getString('manageOrders.completeModal.success'));
      }
      onDismiss(true);
    }
  }, [procureResponse]);

  return (
    <Modal
      data-testid="complete-order-modal"
      onDismiss={dismissModal}
      visible={props.showModal}
      header={<Header variant="h2">{getString('manageOrders.completeModal.modalTitle')}</Header>}
      footer={<Box float="right">{footerElement}</Box>}
    >
      <SpaceBetween direction="vertical" size="s">
        <FormField label={getString('manageOrders.completeModal.licenseNameLabel')}>
          <Input onChange={onLicenseNameChange} value={licenseName} />
        </FormField>
        <FormField label={getString('manageOrders.completeModal.licenseKeyLabel')}>
          <Input onChange={onLicenseKeyChange} value={licenseKey} />
        </FormField>
        <FormField label={<>{getString('manageOrders.completeModal.licenseInstructionsLabel')} {isLoadingCatalogInfo && <Spinner />} </>}>
          <div dangerouslySetInnerHTML={{ __html: licenseInstructions }} />
          <a href={itemEditLink} target='_blank'>Edit Item</a>
        </FormField>
        <FormField label={<>{getString('manageOrders.completeModal.productDownloadUrl')} {isLoadingCatalogInfo && <Spinner />} </>}>
          <Input onChange={onProductDownloadUrlChange} value={productDownloadUrl} />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};
