import { CollectionPreferencesProps } from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { Preferences } from 'common/ui-helpers';
import { ColumnSetting } from 'common/utils/config-models';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export interface usePreferencesResult {
  currentPreferences: Preferences;
  onPreferencesConfirmHandler: NonCancelableEventHandler<CollectionPreferencesProps.Preferences<any>>;
}

export interface usePreferencesProps {
  /**
   * Key to store preferences in browser local storage.
   */

  preferencesKey: string;
  /**
   * Default paging size.
   */

  defaultPaging: number;
  /**
   * Columns settings.
   */
  columnsSettings: ColumnSetting[];

  /**
   * Function to refresh data.
   */
  refreshData: () => void;
}

export function usePreferences(props: usePreferencesProps) {
  const savedPreferences = localStorage.getItem(props.preferencesKey);
  const initialPreferencesValue = useCallback(() => {
    if (savedPreferences) {
      // Take from browser localStorage
      return JSON.parse(savedPreferences);
    } else {
      // Default
      return {
        pageSize: props.defaultPaging,
        stripedRows: true,
        visibleContent: _.map(props.columnsSettings, (e: ColumnSetting) => {
          if (['yes', 'always'].includes(e.visible)) {
            return e.id;
          }
          return '';
        })
      };
    }
  }, []);
  const [currentPreferences, setCurrentPreferences] = useState<Preferences>(initialPreferencesValue());

  // Prevent early calls of the useEffect to have an effect before they are needed
  const [allowUpdates, setAllowUpdates] = useState(false);

  useEffect(() => {
    setAllowUpdates(true);
    setCurrentPreferences(initialPreferencesValue());
  }, []);

  useEffect(() => {
    if (allowUpdates) {
      props.refreshData();
    }
  }, [currentPreferences]);

  /* istanbul ignore next */
  const onPreferencesConfirmHandler: NonCancelableEventHandler<CollectionPreferencesProps.Preferences<any>> = e => {
    localStorage.setItem(props.preferencesKey, JSON.stringify(e.detail));
    setCurrentPreferences(e.detail);
  };

  return { currentPreferences, onPreferencesConfirmHandler };
}
