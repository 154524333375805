import { Box, FormField } from "@amzn/awsui-components-react";
import CopyText from "common/CopyText/CopyText";

interface OrderSidePanelLicenseAttributeBoxProps {
    name?: string;
    label?: string;
    value?: string;
    hoverValue?: any;
    isHtmlContent?: boolean;
    showCopyButton?: boolean;
  }

  const displayHoverText = (value: any): string => value as string ?? "";

  const getSpanWithTextContent = (value: any, isHtml: boolean | undefined) => {
    const result = isHtml ? <span dangerouslySetInnerHTML={{__html: value}} /> : <span>{value}</span>;
    return result;
  };

  const OrderSidePanelLicenseAttributeBox = (props: OrderSidePanelLicenseAttributeBoxProps) => {

    return (
      <Box variant="p">
        <FormField label={props.label}>
          <Box data-testid={props.name}>
            {props.value && <span title={displayHoverText(props.hoverValue)}>
              {getSpanWithTextContent(props.value, props.isHtmlContent)}
              
              {props.showCopyButton && (
                <CopyText copyContent={props.value} isHtml={props.isHtmlContent} />
              )}
            </span>}
          </Box>
        </FormField>
      </Box>
    );
  };

  export default OrderSidePanelLicenseAttributeBox;