import { Alert, Container, Header, Spinner } from '@amzn/awsui-components-react';

import { UserPermissions } from "../../services/user-permissions";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { PERMISSIONS } from '../../configuration/config';
import { getString } from '../ui-string-labels/ui-strings-utils';

type ContainerProps = {
    children: React.ReactNode;
};

/**
 * Shows a loading spinner during initial Authentication/Authorization.
 */
const AuthLoading: FunctionComponent<ContainerProps> = (props: ContainerProps) => {
    const [authFailed, setAuthFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined as Error | undefined);

    useEffect(() => {
        if (!UserPermissions.instance.hasAuthorized && !authFailed) {
            UserPermissions.instance
                .authorize()
                .catch((err) => {
                    setError(err);
                    setAuthFailed(true);
                })
                .finally(() => {
                    if (UserPermissions.instance.hasPermission(PERMISSIONS.SOFTWARE_PROCUREMENT)) {
                        setLoading(false);
                    }
                    else {
                        setError({
                            name: "Unauthorized",
                            message: "User does not have required authorization"
                        });
                        setAuthFailed(true);
                    }
                });
        }
    });

    if (error) {
        return (
            <Alert
                statusIconAriaLabel="Error"
                type="error"
                header={getString("common.authLoading.failedMessage")}
                data-testid="auth-error"
            >
                {error.message}
            </Alert>

        );
    }

    if (loading) {
        return (
            <Container>
                <Header
                    variant="h2"
                    data-testid="auth-loading"
                >
                    {getString("common.authLoading.loadingMessage")}
                </Header>
                <Spinner size='large' />
            </Container>
        );
    }
    return <Fragment>{props.children}</Fragment>
};

export default AuthLoading;
