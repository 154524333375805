import React, { ChangeEvent, ForwardedRef, useCallback, useMemo, useRef } from 'react'

import Button, { ButtonProps } from '@amzn/awsui-components-react/polaris/button'
import FormField from '@amzn/awsui-components-react/polaris/form-field'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'

import { DismissDetail, FileUploadProps } from '../interfaces'
import { getBaseButtonText, fireChangeEvent } from '../internal'

import SelectedFile from './SelectedFile'

const FileUploadComponent = (
  {
    accept,
    ariaLabel,
    ariaRequired,
    buttonText,
    description,
    disabled,
    errorText,
    fileMetadata,
    constraintText,
    id,
    label,
    onChange,
    value
  }: FileUploadProps, ref: ForwardedRef<ButtonProps.Ref>
) => {
  const fileInput = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => fileInput.current && fileInput.current.click()

  const handleChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    let newValue = null;
    if (target.files && target.files[0]) {
      newValue = target.files[0]
    }
    if (onChange) {
      fireChangeEvent(onChange, { value: newValue })
    }
  }, [value, onChange])

  const handleDismiss = useCallback(({ detail }: CustomEvent<DismissDetail>) => {
    let newValue = value
    if (onChange) {
      fireChangeEvent(onChange, { value: newValue })
    }
  }, [value, onChange])

  const baseButtonText = useMemo((): React.ReactNode => {
    return getBaseButtonText(buttonText)
  }, [buttonText])

  const selectedFiles = useMemo((): React.ReactNode => {
    if (errorText) { return null }
    if (value instanceof File) {
      return (
        <SelectedFile
          file={value}
          metadata={fileMetadata}
          multiple={false}
        />
      )
    }
  }, [errorText, value, fileMetadata, handleDismiss])

  return (
    <SpaceBetween size="xs">
      <FormField
        controlId={id}
        label={label}
        description={description}
        errorText={errorText}
        constraintText={constraintText}
      >
        <Button
          data-testid="file-upload-button"
          ref={ref}
          iconName="upload"
          formAction="none"
          disabled={disabled}
          onClick={handleButtonClick}
        >
          <input
            id={id}
            ref={fileInput}
            type="file"
            multiple={false}
            disabled={disabled}
            aria-label={ariaLabel}
            aria-required={ariaRequired ? 'true' : 'false'}
            accept={accept}
            onChange={handleChange}
            hidden
          />
          <span>{baseButtonText}</span>
        </Button>
      </FormField>
      {selectedFiles}
    </SpaceBetween>
  )
}

const FileUpload = React.forwardRef(FileUploadComponent)
export default FileUpload
