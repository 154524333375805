import { FunctionComponent, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import UploadLicenseWidget from "../UploadLicenseWidget/UploadLicenseWidget";
import Header from "@amzn/awsui-components-react/polaris/header";

import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { FileType } from "components/FileUpload";
import { FilesService } from "../../services/files-service";
import { Link, Spinner } from "@amzn/awsui-components-react";

export interface ModalProps {
    visible: boolean;
    onDismissModal: () => void;
    loadingInitalState?: boolean;
    errorInitalState?: boolean;
}

const UploadLicenseModal: FunctionComponent<ModalProps> = ({ visible, onDismissModal, loadingInitalState, errorInitalState }) => {
    const [isLoading, setIsLoading] = useState(loadingInitalState);
    const [error, setError] = useState(errorInitalState);
    const [selectedFile, setSelectedFile] = useState<FileType>(null);

    let headerText: string;
    let footerElement: JSX.Element;
    let contentElement: JSX.Element;

    /* istanbul ignore next */
    async function handleClick() {
        setIsLoading(true);
        const result = await FilesService.instance.uploadBulkFile(selectedFile as File);
        setIsLoading(false);
        setSelectedFile(null);
        
        if (result){
            onDismissModal();
        }else{
            setError(true);
        }
    }

    function handle(file: FileType): void {
        setSelectedFile(file)
    }

    const onDismiss = () => {
        setError(false);
        setSelectedFile(null);
        onDismissModal();
    }    

    const updateModal = (error? : boolean, isLoading?: boolean): [string, JSX.Element, JSX.Element] => {
        let header: string;
        let footer: JSX.Element;
        let content: JSX.Element;

        if (error === true){
            header = getString("uploadLicense.modal.uploadErrorMessage")
            footer = <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={onDismiss}>Close</Button>
                    </SpaceBetween>;
            content = <div></div>;
            return [header, footer, content];
        }

        if (isLoading == true){
            header = getString("uploadLicense.modal.uploadingMessage")
            footer = <div></div>;
            content = <Spinner size='large' />;
            return [header, footer, content];
        }

        header = getString("uploadLicense.modal.headerTitle")
        footer = <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onDismiss}>Cancel</Button>
                    <Button variant="primary" data-cy="admin-upload-license-button" disabled={!selectedFile} onClick={handleClick}>Upload</Button>
                </SpaceBetween>;
        content = <UploadLicenseWidget onFileChange={handle} initialSelectedFile={selectedFile}>
            <Box data-testid="widget-description-box">
                {getString("uploadLicense.widget.descriptionPart1")}
                <Link data-testid="template-link" onFollow={() => FilesService.instance.downloadLicenseUploadTemplate()}>this template</Link>
                {getString("uploadLicense.widget.descriptionPart2")}
            </Box>            
        </UploadLicenseWidget>;
        return [header, footer, content];
    }
    
    [headerText, footerElement, contentElement] = updateModal(error, isLoading);

    return (
        <Modal
            data-testid="upload-modal"
            onDismiss={onDismiss}            
            visible={visible}
            closeAriaLabel="Close modal"
            header={
                <Header variant="h2">
                    {headerText}
                </Header>
            }
            footer={
                <Box float="right">
                    {footerElement}
                </Box>
            }            
            >
            {contentElement}
        </Modal>
    );
}

export default UploadLicenseModal;