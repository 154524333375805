import _ from "lodash";
import { useState } from "react";
import { Box, Button, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { getString } from "../../../common/ui-string-labels/ui-strings-utils";

import { SelectionState } from "../useManageOrdersTable";
import { Order } from "@amzn/ito-client";
import { AssignOrderModal } from "../AssignOrderModal";
import { CancelOrderModal } from "../CancelOrderModal";
import { CompleteOrderModal } from "../CompleteOrderModal";
import { RecallLicenseModal } from '../RecallLicenseModal';
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { ConfirmActionModal } from "common/ConfirmActionModal/ConfirmActionModal";
import { CancelableEventHandler, ClickDetail } from "@amzn/awsui-components-react/polaris/internal/events";
import { CreateOrderModal } from "../CreateOrderModal/CreateOrderModal";
import { OrderStatus } from "configuration/config";

const SYSTEM = "SYSTEM";

export const ManageOrdersTableHeader = (props: {
    totalResults: number;
    selectedItems: Order[];
    procurementMembersSelectOptions: SelectProps.Option[];
    refreshData: (clearPreviousData: boolean) => void;
    useFlashBarItemsActions: useFlashBarItemsActions;
    onDownloadOrders: () => Promise<void>;
    fileId?: string;
    fileName?: string;
}): JSX.Element => {
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showCancelModal, setCancelShowModal] = useState(false);
    const [showCompleteModal, setCompleteShowModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRecallLicenseModal, setRecallLicenseModal] = useState(false);
    const [showConfirmDownloadNewOrders, setConfirmDownloadNewOrders] = useState(false);
    const { useFlashBarItemsActions, procurementMembersSelectOptions } = props;

    let selectionState: SelectionState;
    if (props.selectedItems.length == 0) {
        selectionState = SelectionState.NONE;
    } else if (props.selectedItems.length == 1) {
        selectionState = SelectionState.ONE;
    } else {
        selectionState = SelectionState.MULTIPLE;
    }

    /* istanbul ignore next */
    const onAssignOrders = () => {
        setShowAssignModal(true);
    };

    /* istanbul ignore next */
    const onCreateOrder = () => {
        setShowCreateModal(true);
    };

    /* istanbul ignore next */
    const dismissAssignModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            props.refreshData(true);
        }
        setShowAssignModal(false);
    };

    /* istanbul ignore next */
    const dismissCreateOrderModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            props.refreshData(true);
        }
        setShowCreateModal(false);
    };

    /* istanbul ignore next */
    const onCancelOrder = () => {
        setCancelShowModal(true);
    };

    /* istanbul ignore next */
    const dismissCancelModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            props.refreshData(true);
        }
        setCancelShowModal(false);
    };

    /* istanbul ignore next */
    const onCompleteOrder = () => {
        setCompleteShowModal(true);
    };

    /* istanbul ignore next */
    const dismissCompleteModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            props.refreshData(true);
        }
        setCompleteShowModal(false);
    };

    /* istanbul ignore next */
    const dismissDownloadNewOrdersModal = (shouldRefreshTable: boolean = false) => {
        if (shouldRefreshTable) {
            props.refreshData(true);
        }
        setConfirmDownloadNewOrders(false);
    };

    /* istanbul ignore next */
    const onConfirmDownloadNewOrders: CancelableEventHandler<ClickDetail> | undefined = async () => {
        dismissDownloadNewOrdersModal(false);
        await props.onDownloadOrders();
    };

    const onRecallLicense = () => {
      setRecallLicenseModal(true);
    };
  
    const dismissRecallLicense = (shouldRefreshTable: boolean = false) => {
      if (shouldRefreshTable) {
        props.refreshData(true);
      }
      setRecallLicenseModal(false);
    };

    const disableCancelOrComplete =
        selectionState !== SelectionState.ONE ||
        props.selectedItems[0].status === OrderStatus.COMPLETED ||
        props.selectedItems[0].status === OrderStatus.CANCELLED;

    const disableRecallLicense =
      selectionState !== SelectionState.ONE ||
      props.selectedItems[0].status !== OrderStatus.COMPLETED  ||
      props.selectedItems[0].assignedTo === SYSTEM;

    const disableAssigneeButton = (): boolean => {
        if (selectionState == SelectionState.NONE) return true;

        for (const order of props.selectedItems) {
            if ([OrderStatus.CANCELLED, OrderStatus.COMPLETED].includes(order.status as OrderStatus)) {
                return true;
            }
        }

        return false;
    };

    return (
        <SpaceBetween size="m">
            <Header
                data-testid="manage-orders-table-header"
                variant="awsui-h1-sticky"
                counter={`(${props.totalResults})`}
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button data-testid="recall-license-button" variant="normal" onClick={onRecallLicense} disabled={disableRecallLicense}>
                            {getString('manageOrders.table.recallLicenseButton')}
                        </Button>
                        <Button data-testid="create-order-button" onClick={onCreateOrder}>
                            {getString("manageOrders.table.createOrderButton")}
                        </Button>
                        <Button
                            data-testid="assign-order-button"
                            disabled={disableAssigneeButton()}
                            onClick={onAssignOrders}
                        >
                            {getString("manageOrders.table.assignOrderButton")}
                        </Button>
                        <Button
                            data-testid="cancel-order-button"
                            onClick={onCancelOrder}
                            disabled={disableCancelOrComplete}
                        >
                            {getString("manageOrders.table.cancelOrderButton")}
                        </Button>
                        <Button
                            data-testid="complete-order-button"
                            onClick={onCompleteOrder}
                            disabled={disableCancelOrComplete}
                        >
                            {getString("manageOrders.table.completeOrderButton")}
                        </Button>
                        {props.fileId ? undefined : (
                            <Button
                                data-testid="download-new-orders-button"
                                variant="primary"
                                onClick={() => setConfirmDownloadNewOrders(true)}
                            >
                                {getString("manageOrders.table.downloadNewOrdersButton")}
                            </Button>
                        )}
                    </SpaceBetween>
                }
            >
                {props.fileId
                    ? `${getString("manageOrders.table.uploadedOrdersheaderTitle")} ${props.fileName}`
                    : getString("manageOrders.table.headerTitle")}
            </Header>
            {showAssignModal && (
                <AssignOrderModal
                    procurementMembersSelectOptions={procurementMembersSelectOptions}
                    onDismiss={dismissAssignModal}
                    orders={props.selectedItems}
                    flashBarItemsActions={useFlashBarItemsActions!}
                />
            )}

            {showCreateModal && (
                <CreateOrderModal onDismiss={dismissCreateOrderModal} flashBarItemsActions={useFlashBarItemsActions!} />
            )}

            {showCancelModal && (
                <CancelOrderModal
                    showModal={showCancelModal}
                    onDismiss={dismissCancelModal}
                    flashBarItemsActions={useFlashBarItemsActions!}
                    selectedItem={props.selectedItems[0]}
                />
            )}

            {showCompleteModal && (
                <CompleteOrderModal
                    showModal={showCompleteModal}
                    onDismiss={dismissCompleteModal}
                    flashBarItemsActions={useFlashBarItemsActions!}
                    selectedItem={props.selectedItems[0]}
                />
            )}
            
            {showRecallLicenseModal && (
                <RecallLicenseModal
                  showModal={showRecallLicenseModal}
                  onDismiss={dismissRecallLicense}
                  flashBarItemsActions={useFlashBarItemsActions!}
                  selectedItem={props.selectedItems[0]}
                />
            )}

            {showConfirmDownloadNewOrders && (
                <ConfirmActionModal
                    isProcessing={false}
                    visible={showConfirmDownloadNewOrders}
                    modalTitle={getString("manageOrders.table.downloadNewOrdersButton")}
                    onDismiss={dismissDownloadNewOrdersModal}
                    warningText={getString("manageOrders.table.confirmDownloadNewOrders")}
                    informationalText={getString("manageOrders.table.confirmDownloadNewOrdersInfo")}
                    onAction={onConfirmDownloadNewOrders}
                />
            )}
            <Box color="text-status-inactive">{getString("manageOrders.table.headerDescription")}</Box>
        </SpaceBetween>
    );
};
