import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import DownloadedOrdersTable from '../../components/DownloadedOrdersTable/DownloadedOrdersTable';
import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';

import Paths from "../../common/paths/paths";

// Breadcrumbs configuration
const breadcrumbs = {
  items:
  [
    { text: Paths.Admin.name, href: Paths.ordersDownloaded.url },
    { text: Paths.ordersDownloaded.name, href: Paths.ordersDownloaded.url },
  ]
}

// Functional component for the Downloaded Orders page
const DownloadedOrdersPage = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  return (
    <AppLayout
      content={<DownloadedOrdersTable />}
      breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
      navigation={<SideNavigationMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
    />
  );
}

export default DownloadedOrdersPage;