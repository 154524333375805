import { useState, useEffect } from "react";
import { API_BASE_URL } from "../configuration/config";
import {
    GetOrders_AllCommandInput,
    GetOrders_AllCommandOutput,
    GetFilesCommandInput,
    GetFilesCommandOutput,
    ITO,
    Order,
    PostCommentCommandInput,
    PostCommentCommandOutput,
    ProcureOrderItemsCommandInput,
    ProcureOrderItemsCommandOutput,
    UpdateOneOrderStatus,
    UpdateOrderStatusBatchCommandInput,
    UpdateOrderStatusBatchCommandOutput,
    UpdateOrderStatusCommandInput,
    UpdateOrderStatusCommandOutput,
    GetBulkDownloadHistoryCommandOutput,
    GetBulkDownloadHistoryCommandInput,
    SubmitOrderCommandOutput,
    SubmitOrderRequest,
    RecallOrderCommandInput,
    RecallOrderCommandOutput,
    ITOClientConfig
} from "@amzn/ito-client";
import { SentryFetchHttpHandler, CALLER_ABORTED } from "@amzn/sentry-fetch-http-handler";
import { AbortController, AbortSignal } from "@aws-sdk/abort-controller";
import { v4 as uuidv4 } from "uuid";
import { Error as HookError } from "./hook-helper";
import { OrderStatus } from "configuration/config";

interface UpdateOrdersParams {
    assignedTo?: string;
    status?: OrderStatus;
}

interface assignOrderFunction {
    (order: Order, options: UpdateOrdersParams): void;
    (order: Order, status: OrderStatus): void;
}

export interface RecallOrderProps {
  order: Order,
  licenseName: string,
  licenseKey: string,
  csrfToken: string,
}

export interface GetOrdersProps {
    clearPreviousData?: boolean;
    pageSize?: number;
    pageIndex?: number;
    filters?: Record<string, string[]>;
}

export interface SubmitOrdersProps {
    dataStateOverride?: [Order | undefined, React.Dispatch<React.SetStateAction<Order | undefined>>];
    loadingStateOverride?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    errorStateOverride?: [string, React.Dispatch<React.SetStateAction<string>>];
}

export interface GetFilesProps {
    clearPreviousData?: boolean;
    pageSize?: number;
    pageIndex?: number;
    fromAutoRefresh?: boolean;
}

// Client
const requestHandler = new SentryFetchHttpHandler();
const client = new ITO({
    endpoint: `${API_BASE_URL}/ito`,
    region: "*",
    credentials: { accessKeyId: "", secretAccessKey: "" },
    requestHandler: requestHandler,
} as ITOClientConfig);

type GetOrderResult = GetOrders_AllCommandOutput | null | undefined;
export function useGetOrders(
    mainProps: GetOrdersProps,
    defaultValue: GetOrderResult,
): [GetOrderResult, boolean, (props: GetOrdersProps) => void] {
    const [data, setData] = useState<GetOrderResult>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { getAbortSignal } = useAbortSignal();

    function doGetOrders(props: GetOrdersProps) {
        // its loading now
        setLoading(true);

        // Aborting
        const signal = getAbortSignal();

        // Pagination
        if (!props.pageIndex) {
            props.pageIndex = 1;
        }
        if (!props.pageSize) {
            props.pageSize = 20;
        }

        // Filtering
        if (!props.filters) props.filters = {};

        // Clear prev data
        if (props.clearPreviousData && props.clearPreviousData === true) {
            setData(undefined);
        }

        const request: GetOrders_AllCommandInput = {
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
            filters: props.filters,
        };

        client.getOrders_All(
            request,
            { abortSignal: signal },
            (err: Error, response?: GetOrders_AllCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response || !response.orders) {
                    console.error(err);
                    throw new Error("No orders data found");
                }
                setData(response);
                setLoading(false);
            },
        );
    }

    if (defaultValue === null) {
        // No default value, then get from API
        useEffect(() => {
            doGetOrders(mainProps);
        }, [defaultValue]);
    }

    return [data, isLoading, doGetOrders];
}

export function useSubmitOrder(props?: SubmitOrdersProps) {
    const [data, setData] = props?.dataStateOverride ?? useState<Order>();
    const [error, setError] = props?.errorStateOverride ?? useState<string>("");
    const [isLoading, setLoading] = props?.loadingStateOverride ?? useState<boolean>(false);
    const { getAbortSignal } = useAbortSignal();

    function doSubmitOrder(request: SubmitOrderRequest, csrfToken: string): any {
        // its loading now
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        // Aborting
        const signal = getAbortSignal();

        client.submitOrder(
            request,
            { abortSignal: signal },
            (err: Error, response?: SubmitOrderCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }
                setLoading(false);

                /* istanbul ignore next */
                if (!response) {
                    setError(err.message);
                    console.error(err);
                    return;
                }

                setData(response);
            },
        );
    }
    return {
        data,
        error,
        isLoading,
        doSubmitOrder,
        setData,
    };
}

/* istanbul ignore next */
export function useUpdateOrdersStatus() {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setLoading] = useState(false);
    const { getAbortSignal } = useAbortSignal();
    const [data, setData] = useState<UpdateOrderStatusBatchCommandOutput | UpdateOrderStatusCommandOutput | undefined>(
        undefined,
    );

    function doUpdateOrders(orders: Order[], updateProps: UpdateOrdersParams, csrfToken: string) {
        if (orders.length === 0) {
            setError(new Error("Orders list is empty."));
            return;
        }

        if (!updateProps.assignedTo && !updateProps.status) {
            setError(new Error("Must provide an status or an assignedTo"));
            return;
        }

        const updateRequests = orders.map((order) => {
            return { orderId: order.orderId, ...updateProps };
        });

        if (updateRequests.length > 1) {
            doUpdateOrderStatus_Batch(updateRequests, csrfToken);
        } else {
            doUpdateOrderStatus_One(updateRequests[0], csrfToken);
        }
    }

    function doUpdateOrderStatus_Batch(orders: UpdateOneOrderStatus[], csrfToken: string) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        // Aborting
        const signal = getAbortSignal();

        const request: UpdateOrderStatusBatchCommandInput = {
            orders,
        };

        client.updateOrderStatusBatch(
            request,
            { abortSignal: signal },
            (err: Error, response?: UpdateOrderStatusBatchCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response || !response.orders) {
                    setLoading(false);
                    console.error(err);
                    setError(err);
                    throw err;
                }
                setData(response);
                setLoading(false);
            },
        );
    }

    function doUpdateOrderStatus_One(orderStatusUpdate: UpdateOneOrderStatus, csrfToken: string) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        // Aborting
        const signal = getAbortSignal();

        const request: UpdateOrderStatusCommandInput = { ...orderStatusUpdate };

        client.updateOrderStatus(
            request,
            { abortSignal: signal },
            (err: Error, response?: UpdateOrderStatusCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response) {
                    setLoading(false);
                    setError(err);
                    console.error(err);
                    throw err;
                }

                setData(response);
                setLoading(false);
            },
        );
    }

    return { isLoading, setLoading, data, setData, error, setError, doUpdateOrders };
}

/* istanbul ignore next */
export function usePostComment(): [
    PostCommentCommandOutput | undefined,
    boolean,
    Error | null,
    (taxonomyId: string, comment: string, csrfToken: string) => void,
] {
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState<PostCommentCommandOutput | undefined>(undefined);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const { getAbortSignal } = useAbortSignal();

    function doPostComment(taxonomyId: string, comment: string, csrfToken: string) {
        // it's processing now
        setProcessing(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        // Aborting
        const signal = getAbortSignal();

        const request: PostCommentCommandInput = {
            taxonomyId,
            comment,
        };

        client.postComment(
            request,
            { abortSignal: signal },
            (err: Error, response?: PostCommentCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response) {
                    console.error(err);
                    setError(error);
                    throw new Error("No comment was posted");
                }
                setResponse(response);
                setProcessing(false);
            },
        );
    }

    return [response, isProcessing, error, doPostComment];
}

type DoProcureOrderHandler = (
    order: Order,
    licenseName: string,
    licenseKey: string,
    licenseInstructions: string,
    productDownloadUrl: string,
    csrfToken: string,
) => void;

/* istanbul ignore next */
export function useProcureOrder(): [
    ProcureOrderItemsCommandOutput | undefined,
    boolean,
    Error | null,
    DoProcureOrderHandler,
] {
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState<ProcureOrderItemsCommandOutput | undefined>(undefined);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const { getAbortSignal } = useAbortSignal();

    function doProcureOrder(
        order: Order,
        licenseName: string,
        licenseKey: string,
        licenseInstructions: string,
        productDownloadUrl: string,
        csrfToken: string,
    ) {
        // it's processing now
        setProcessing(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        // Aborting
        const signal = getAbortSignal();

        if (order.items?.length === 0) {
            setError(new Error("Error procuring order. It does not contain any item to be procured."));
            return;
        }

        const item = order.items![0];

        const request: ProcureOrderItemsCommandInput = {
            orderId: order.orderId,
            assets: [
                {
                    assetGuid: uuidv4(),
                    itemId: item.itemId,
                    price: item.price,
                    details: {
                        licenseName,
                        licenseKey,
                        licenseInstructions,
                        productDownloadUrl,
                    },
                },
            ],
        };

        client.procureOrderItems(
            request,
            { abortSignal: signal },
            (err: Error, response?: ProcureOrderItemsCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response) {
                    console.error(err);
                    setError(error);
                    throw new Error("An error occurred procuring the order.");
                }
                setResponse(response);
                setProcessing(false);
            },
        );
    }

    return [response, isProcessing, error, doProcureOrder];
}

/* istanbul ignore next */
function useAbortSignal() {
    const [currentAbortController, setAbortController] = useState<AbortController | undefined>(undefined);

    // Aborting
    const getAbortSignal = (): AbortSignal => {
        let abortController = currentAbortController;
        if (!abortController) {
            abortController = new AbortController();
            setAbortController(abortController);
        } else {
            abortController.abort();
        }

        return abortController.signal;
    };

    return { getAbortSignal };
}

type GetFilesResult = GetFilesCommandOutput | null | undefined;
export function useGetOrdersUploadFiles(
    mainProps: GetFilesProps,
    defaultValue: GetFilesResult,
): [GetFilesResult, boolean, (props: GetFilesProps) => void] {
    const [data, setData] = useState<GetFilesResult>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { getAbortSignal } = useAbortSignal();

    function doGetFiles(props: GetFilesProps) {
        if (!props.fromAutoRefresh) {
            setLoading(true);
        }

        // Aborting
        const signal = getAbortSignal();

        // Pagination
        if (!props.pageIndex) props.pageIndex = 1;
        if (!props.pageSize) props.pageSize = 20; //default

        // Clear prev data
        if (props.clearPreviousData && props.clearPreviousData === true) {
            setData(undefined);
        }

        const request: GetFilesCommandInput = {
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
        };

        client.getFiles(
            request,
            { abortSignal: signal },
            (err: Error, response?: GetFilesCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response || !response.files) {
                    console.error(err);
                    throw new Error("No files data found");
                }
                setData(response);
                setLoading(false);
            },
        );
    }

    if (!defaultValue) {
        // No default value, then get from API
        useEffect(() => {
            doGetFiles(mainProps);
        }, [defaultValue]);
    }

    return [data, isLoading, doGetFiles];
}

type GetHistoryFilesResult = GetBulkDownloadHistoryCommandOutput | null | undefined;
export function useGetOrdersHistoryFiles(
    mainProps: GetFilesProps,
    defaultValue: GetHistoryFilesResult,
): [GetHistoryFilesResult, boolean, (props: GetFilesProps) => void] {
    const [data, setData] = useState<GetHistoryFilesResult>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { getAbortSignal } = useAbortSignal();

    function doGetBulkDownloadHistory(props: GetFilesProps) {
        if (!props.fromAutoRefresh) {
            setLoading(true);
        }

        // Aborting
        const signal = getAbortSignal();

        // Pagination
        if (!props.pageIndex) props.pageIndex = 1;
        if (!props.pageSize) props.pageSize = 30; //default

        // Clear prev data
        if (props.clearPreviousData && props.clearPreviousData === true) {
            setData(undefined);
        }

        const request: GetBulkDownloadHistoryCommandInput = {
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
        };

        client.getBulkDownloadHistory(
            request,
            { abortSignal: signal },
            (err: Error, response?: GetBulkDownloadHistoryCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response || !response.files) {
                    console.error(err);
                    throw new Error("No files data found");
                }
                setData(response);
                setLoading(false);
            },
        );
    }

    if (!defaultValue) {
        // No default value, then get from API
        useEffect(() => {
            doGetBulkDownloadHistory(mainProps);
        }, [defaultValue]);
    }

    return [data, isLoading, doGetBulkDownloadHistory];
}

export function useRecallLicenseFromOrder(): {
  recallResponse: RecallOrderCommandOutput | undefined,
  isProcessing: boolean,
  error: HookError | null,
  doRecallOrder: (props: RecallOrderProps) => void
} {
  const [error, setError] = useState<HookError>({ hasError: false });
  const [response, setResponse] = useState<RecallOrderCommandOutput | undefined>(undefined);
  const [isProcessing, setProcessing] = useState<boolean>(false);

  function doRecallOrder(props: RecallOrderProps) {    
    setProcessing(true);

    requestHandler.pushHeader("x-csrf-token", props.csrfToken);

    if (!props.order.items || props.order.items.length <= 0) {
      setError( {hasError: true, message: "Order does not have available items"} );
      return;
    }

    const request: RecallOrderCommandInput = {
      orderId: props.order.orderId,
      assets: [
        {
          itemId: props.order.items![0].itemId,
          assetId: props.order.assets![0].assetId,
          details: {
            licenseName: props.licenseName,
            licenseKey: props.licenseKey,
          }
        }
      ]
    };

    client.recallOrder(request, (err: any, response?: RecallOrderCommandOutput | undefined) => {
      if (!response || !response.items) {
          const errorMessage = "Error recalling the order: " + err;
          console.error(errorMessage);
          setProcessing(false);
          setError({
              hasError: true,
              message: errorMessage
            })
      }
      setResponse(response);
      setProcessing(false);
  });
  }

  return { recallResponse: response, isProcessing, error, doRecallOrder};
}