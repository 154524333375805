import React from 'react';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';

export interface CheckboxItemProps {
    name?: string;
    id: string;
    label?: string;
    key?: any;
}

const CheckboxItem = (props: CheckboxItemProps) => {
  const [checked, setChecked] = React.useState(false);
  return <Checkbox onChange={({ detail }) => setChecked(detail.checked)} name={props.name} checked={checked}>{props.label}</Checkbox>;
};

export default CheckboxItem;