import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import { useNavigate } from 'react-router-dom';

interface NavigationBreadcrumbsProps {
  /**
   * Each item adds a new level for the breadcrumb: item1 > item2 > item3
   */
  items: { text: string, href: string }[]
}

const NavigationBreadcrumbs = ({ items }: NavigationBreadcrumbsProps) => {

  const navigate = useNavigate();
  
  /**
   * Prevents reloading the page and lets react router handle the redirect
   * @param event
   */
  function onFollowHandler(event: CustomEvent<BreadcrumbGroupProps.Item>) {
    event.preventDefault();
    if (event.detail.href) {
      navigate(event.detail.href);
    }
  };

  return (
    <BreadcrumbGroup items={items} onFollow={onFollowHandler} />
  );
}

export default NavigationBreadcrumbs;