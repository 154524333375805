import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import UploadLicenseTable from '../../components/UploadLicenseTable/UploadLicenseTable';
import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';

import Paths from "../../common/paths/paths";

const breadcrumbs = {
  items:
  [
    { text: Paths.Admin.name, href: Paths.uploadLicense.url },
    { text: Paths.uploadLicense.name, href: Paths.uploadLicense.url },
  ]
}

const UploadLicensePage = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  return (
    <AppLayout
      content={<UploadLicenseTable />}
      breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
      navigation={<SideNavigationMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
    />
  );
}

export default UploadLicensePage;