import Link, { LinkProps } from "@amzn/awsui-components-react/polaris/link";
import { useNavigate } from "react-router-dom";

/**
 * Link used for navigation in internal paths
 * @param props
 * @returns 
 */
const InternalLink = (props: LinkProps) => {
   const navigate = useNavigate();

   /**
   * Prevents reloading the page and lets react router handle the redirect
   * @param event
   */
   const onFollowHandler = (event: CustomEvent<LinkProps.FollowDetail>) => {
      if (event.detail.href) {
         event.preventDefault();
         navigate(event.detail.href!);
      }
   };

   return <Link {...props} onFollow={onFollowHandler} />;
}

export default InternalLink;