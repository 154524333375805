import { getString } from "../ui-string-labels/ui-strings-utils";
import { Page } from "../../interfaces"

export default class Paths {
     // Upload license page is being used as Home page
    static readonly Admin: Page = {name: "Admin", url: "/uploadLicense"};
    
    static readonly manageLicense: Page = { name: "Manage license", url: "/manageLicense"};
    static readonly uploadLicense: Page = { name: "Upload license", url: "/uploadLicense"};
    static readonly licenseDetails: Page = { name: "License details", url: "/licenseDetails/:id"};

    static readonly manageOrders: Page = { name: getString("manageOrders.table.headerTitle"), url: "/orders"};
    static readonly uploadOrders: Page = { name: getString("uploadOrders.table.headerTitle"), url: "/orders/upload"};
    static readonly ordersUploaded: Page = { name: "Orders uploaded", url: "/orders/:id"};
    static readonly ordersDownloaded: Page = { name: "Downloaded orders", url: "/orders/dowloaded"};
}
