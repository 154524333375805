import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FileUpload, { FileSize, FileType } from "../FileUpload";
import { UIHelpers } from "../../common/ui-helpers";


export interface UploadLicenseWidgetProps {
    onFileChange?: (newFile: FileType) => void;
    initialSelectedFile: FileType;
    children: JSX.Element|JSX.Element[],
}

const UploadLicenseWidget = ({
    onFileChange,
    initialSelectedFile,
    children,
}: UploadLicenseWidgetProps) => {
    let selectedFile = initialSelectedFile;

    function fileSize():FileSize{
        if (selectedFile){
            let size = (selectedFile as File).size;
            return UIHelpers.getBytesFormat(size);
        }
        return FileSize.BYTES;
    }
    
    return (
        <Box>
            <SpaceBetween direction="vertical" size="s">
                {children}
                <FileUpload
                    id="file-upload-widget"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    value={selectedFile}
                    fileMetadata={{
                        lastModified: true,
                        name: true,
                        size: fileSize(),
                        thumbnail: false
                    }}
                    onChange={({ detail }) => { selectedFile = detail.value; onFileChange!(detail.value) }}
                />
            </SpaceBetween>
        </Box>
    );
}

export default UploadLicenseWidget;