import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter/interfaces";

export const convertTokensToPropertyFilter = (tokens: ReadonlyArray<PropertyFilterProps.Token>): { [key: string]: string[] } => {
    const result: { [key: string]: string[] }  = {};

    tokens.map(i => {
        if (Object.keys(result).includes(i.propertyKey!)){
            result[i.propertyKey!].push(i.value);
        }
        else {
            result[i.propertyKey!] = [i.value];
        }
    });

    return result;
}