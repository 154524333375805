import { Button, StatusIndicator, Popover } from '@amzn/awsui-components-react';

interface CopyTextProps {
    textContent?: string;
    copyContent: string;
    isHtml?: boolean;
}

function copyToClipboard(text: string) {
    return navigator.clipboard.writeText(text);
}

function getContentText(value: any, isHtmlContent: boolean | undefined) {
    if (value && isHtmlContent) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = value;
        return divContainer.textContent || divContainer.innerText || "";
    }

    return value ?? "";
}

const CopyText = ({ textContent, copyContent, isHtml }: CopyTextProps) => {
    const contentText = getContentText(textContent, isHtml);
    const contentToCopy = getContentText(copyContent, isHtml);

    return (
        <Popover
            size="small"
            position="top"
            dismissButton={false}
            triggerType="custom"
            content={<StatusIndicator type="success">{contentToCopy} copied!</StatusIndicator>}>
            {contentText}
            <Button
                variant="inline-icon"
                iconName="copy"
                onClick={() => copyToClipboard(contentToCopy)}
            />
        </Popover>
    );
}

export default CopyText;