import { configure, default as SentryFetch } from "@amzn/sentry-fetch";

export class BaseService {
  protected sentryFetch: typeof SentryFetch;
  protected baseUrl: string;

  /**
   * Constructor for base service
   * @param baseUrl The base URL for the API. Should contain the schema, domain and base path
   */
  constructor(baseUrl: string) {
    this.sentryFetch = configure({
      debug: false,
      followMidwayStepUp: true,
      failOnInvalidSession: true,
      preserveRedirectUri: true,
    });

    this.baseUrl = baseUrl;
  }

  /**
   * Executes a fetch to the specified path in the API.
   * @param fullPath The path to the endpoint. E.g. /user-permissions.
   * @param init The initialization options. CORS and Credentials will be enabled by default.
   * @returns The fetch response.
   */
  public async fetch(fullPath: string, init?: RequestInit): Promise<Response> {
    fullPath = fullPath.startsWith("/") ? fullPath : `/${fullPath}`;
    const url = `${this.baseUrl}${fullPath}`;

    return await this.sentryFetch(url, {
      credentials: "include",
      mode: "cors",
      ...init,
    });
  }
}
