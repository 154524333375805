import { useEffect, useState } from "react";
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Box,
  Button,
  CollectionPreferences,
  Header,
  Pagination,
  PaginationProps,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react";

import UploadLicenseModal from "../UploadLicenseModal/UploadLicenseModal";
import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";
import InternalLink from "../../common/navigation/InternalLink";

import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { FilesService } from "../../services/files-service";
import { useGetFiles } from "../../hooks/tinvent";
import { buildColumnDefinitions } from "../../common/utils/table-utils";
import filesTableConfig from "./files.table-config.json";
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";
import _ from "lodash";

const COLUMN_DEFINITIONS = buildColumnDefinitions(filesTableConfig.columns);


const UPLOADED_STATUS = "UPLOADED";
const PROCESSING_STATUS = "PROCESSING";
const UploadLicenseTable = () => {
  const [visibleLicenseModal, setVisibleLicenseModal] = useState(false);

  const PAGE_SIZE: number = 25;
  const MILLISECONDS_BETWEEN_REFRESH_WHEN_PENDING_STATUS = 15000; // 15 seconds
  const MILLISECONDS_BETWEEN_REFRESH_WHEN_NO_PENDING_STATUS = 300000; // 5 minutes  

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [filesData, isLoading, error, doGetFiles] = useGetFiles({ pageSize: PAGE_SIZE, pageIndex: 1 }, undefined);

  const getRefreshInterval = (): number => {
    if (_.findIndex(filesData?.files, i => i.status === UPLOADED_STATUS || i.status === PROCESSING_STATUS) !== -1) {
      return MILLISECONDS_BETWEEN_REFRESH_WHEN_PENDING_STATUS;
    } else {
      return MILLISECONDS_BETWEEN_REFRESH_WHEN_NO_PENDING_STATUS;
    }
  }  

  const dismissModal = () => { 
    setVisibleLicenseModal(false); 
    doGetFiles({ pageSize: PAGE_SIZE, pageIndex: currentPageIndex });
  }

  // Pagination
  const onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail> = (e) => {
    doGetFiles({ pageSize: PAGE_SIZE, pageIndex: e.detail.currentPageIndex });
    setCurrentPageIndex(e.detail.currentPageIndex);
  };  

  // Ready to display
  let pagesCount = 0;
  let totalResults = 0;
  if (filesData && filesData.totalResults && filesData.totalResults > 0) {
    totalResults = filesData.totalResults;
    pagesCount = Math.ceil(filesData.totalResults / PAGE_SIZE);
  }

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      doGetFiles({ pageSize: PAGE_SIZE, pageIndex: currentPageIndex, fromAutoRefresh: true })
    }, getRefreshInterval());

    return () => clearInterval(interval);
  }, [doGetFiles]);

  return (
    <Box data-testid="upload-license-box">
      <UploadLicenseModal visible={visibleLicenseModal} onDismissModal={dismissModal} />
      {error?.hasError && 
      (<Alert statusIconAriaLabel="Error" type="error" header={getString("uploadLicense.table.errorTitle")}>
        {error?.message}
      </Alert>)}
      <Table
        data-testid="upload-license-table"
        header={
          <SpaceBetween size="m">
            <Header
              variant="awsui-h1-sticky"
              counter={`(${totalResults})`}
              description={getString("uploadLicense.table.headerDescription")}
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button data-testid="download-button" variant="normal" onClick={() => FilesService.instance.downloadLicenseUploadTemplate()}>
                    {getString("uploadLicense.table.downloadTemplateButton")}
                  </Button>
                  <Button data-testid="upload-button" variant="primary" onClick={() => setVisibleLicenseModal(true)}>
                    {getString("uploadLicense.table.uploadLicenseButton")}
                  </Button>
                </SpaceBetween>
              }
            >
              {getString("uploadLicense.table.headerTitle")}
            </Header>
          </SpaceBetween>
        }
        pagination={
          <Pagination
            pagesCount={pagesCount}
            currentPageIndex={currentPageIndex}
            onChange={onPageClick}
            ariaLabels={{
              nextPageLabel: getString("common.pagination.nextPage"),
              previousPageLabel: getString("common.pagination.nextPage"),
              pageLabel: (pageNumber: number) =>
                getString("common.pagination.currentPage", { pageNumber, pagesCount })
            }}
          />
        }
        empty={<EmptyTableBox />}
        preferences={<CollectionPreferences title={getString("common.table.preferencesTitle")} confirmLabel={getString("common.table.confirmLabel")} cancelLabel={getString("common.table.cancelLabel")} />}
        columnDefinitions={COLUMN_DEFINITIONS}
        loading={isLoading}
        loadingText="Loading files"
        items={filesData?.files!}
        variant="full-page"
      />
    </Box>
  );
}

export default UploadLicenseTable;