import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import ManageLicenseTable from '../../components/ManageLicenseTable/ManageLicenseTable';
import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';

import Paths from "../../common/paths/paths";
import { useFlashBarItems } from 'common/UseFlashBarItems/useFlashBarItems';
import { Flashbar } from '@amzn/awsui-components-react';

const breadcrumbs = {
  items:
  [
    { text: Paths.Admin.name, href: Paths.uploadLicense.url },
    { text: Paths.manageLicense.name, href: Paths.manageLicense.url }
  ]
}

const ManageLicensePage = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const flashBarItemsHook = useFlashBarItems();

  return (
    <AppLayout
      content={<ManageLicenseTable useFlashBarItemsActions={flashBarItemsHook.actions} />}
      breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
      navigation={<SideNavigationMenu />}
      navigationOpen={navigationOpen}
      notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems />}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
    />
  );
}

export default ManageLicensePage;