import './LicenseDetailsTable.scss';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Box,
  CollectionPreferences,
  Pagination,
  PaginationProps,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import PropertyFilter, { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { LicenseDetail } from '@amzn/tinvent-typescript-client';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { ColumnSetting } from 'common/utils/config-models';
import { usePreferences } from 'hooks/usePreferences';
import { CurrentPage } from 'interfaces';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import EmptyTableBox from '../../common/EmptyTableBox/EmptyTableBox';
import { getPropertyFilterString, getString } from '../../common/ui-string-labels/ui-strings-utils';
import { convertTokensToPropertyFilter } from '../../common/utils';
import {
  buildColumnDefinitions,
  buildFilteringProperties,
  buildVisibleContentOptions,
  defaultPagingOptions,
  SortingState} from '../../common/utils/table-utils';
import { useGetLicenseDetails } from '../../hooks/tinvent';
import LicenseDetailsTableHeader from './LicenseDetailsTableHeader/LicenseDetailsTableHeader';
import licensesTableConfig from './licenses.table-config.json';

export interface LicenseDetailsTableProps {
  useFlashBarItemsActions?: useFlashBarItemsActions;
}

const licenseDetailsTable = (props: LicenseDetailsTableProps) => {
  // Constants and variables
  const PREFERENCES_KEY = 'LicenseDetailsPreferences';
  const columns: ColumnSetting[] = licensesTableConfig.columns;
  const FILTERING_PROPERTIES = buildFilteringProperties(columns);
  const VISIBLE_CONTENT_COLUMN_OPTIONS = buildVisibleContentOptions(columns);
  const ASSIGNED_OPTIONS = [
    { label: 'Assigned - No', value: 'false' },
    { label: 'Assigned - Yes', value: 'true' }
  ];

  const {useFlashBarItemsActions} = props;

  const [
    selectedItems,
    setSelectedItems
  ] = useState<LicenseDetail[]>([]);

  const { id } = useParams();
  const location = useLocation();
  let productName = id;
  if (location.state) {
    productName = location.state.value;
  }

  // State
  const [currentSorting, setCurrentSorting] = useState<SortingState | undefined>(undefined);
  const [currentPageIndex, setCurrentPageIndex] = useState<CurrentPage>({ index: 1, isPageReset: true });
  const [selectedAssignedOption, setSelectedAssignedOption] = useState(ASSIGNED_OPTIONS[0]);
  const [currentFilteringQuery, setFilteringQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'and'
  });

  // Preferences
  const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
    preferencesKey: PREFERENCES_KEY,
    refreshData: () => refreshTableData(true),
    columnsSettings: columns,
    defaultPaging: defaultPagingOptions[0].value
  });

  // Get data from TInvent
  const [data, isLoading, error, doGetLicenseDetails] = useGetLicenseDetails(
    { productTaxonomyId: id, pageSize: currentPreferences.pageSize!, pageIndex: 1 },
    undefined
  );

  // Filtering and Sorting
  useEffect(() => {
    refreshTableData(true);
  }, [
    currentFilteringQuery,
    currentSorting?.sortBy,
    currentSorting?.sortOrder,
    selectedAssignedOption.label,
    selectedAssignedOption.value
  ]);

  // Pagination
  useEffect(() => {
    if (!currentPageIndex.isPageReset) {
      refreshTableData(false);
    }
  }, [currentPageIndex.index, currentPageIndex.isPageReset]);

  // Filtering Events
  /* istanbul ignore next */
  const onPropertyFilterChange: NonCancelableEventHandler<PropertyFilterProps.Query> = e => {
    setFilteringQuery(e.detail);
  };

  /* istanbul ignore next */
  const onAssignedChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = e => {
    setSelectedAssignedOption({
      label: e.detail.selectedOption.label!,
      value: e.detail.selectedOption.value!
    });
  };

  // Pagination
  /* istanbul ignore next */
  const onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail> = e => {
    setCurrentPageIndex({ index: e.detail.currentPageIndex, isPageReset: false });
  };

  // Select items form the table
  const handleSelectionChange: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>> = e => {
    setSelectedItems(e.detail.selectedItems);
};

  // Sorting
  /* istanbul ignore next */
  const onSortingChange: NonCancelableEventHandler<TableProps.SortingState<LicenseDetail>> = async e => {
    const sortBy = e.detail.sortingColumn.sortingField;
    const sortOrder = e.detail.isDescending ? 'sort.desc' : 'sort.asc';
    setCurrentSorting({ sortBy, sortOrder });
    collectionProps.onSortingChange!(e);
  };

  const { collectionProps } = useCollection(data?.items ?? [], {
    sorting: {}
  });

  // Ready to display
  let pagesCount = 0;
  let totalResults = 0;
  if (data && data.totalResults && data.totalResults > 0) {
    totalResults = data.totalResults;
    pagesCount = Math.ceil(data.totalResults / currentPreferences.pageSize!);
  }

  /* istanbul ignore next */
  const refreshTableData = (clearPreviousData: boolean) => {
    let pageIndexForQuery = currentPageIndex.index;
    if (clearPreviousData) {
      pageIndexForQuery = 1;
      setCurrentPageIndex({ index: 1, isPageReset: true });
    }
    const filters: { [key: string]: string[] } = convertTokensToPropertyFilter(currentFilteringQuery.tokens);
    if (currentSorting && currentSorting.sortOrder && currentSorting.sortBy) {
      filters[currentSorting.sortOrder] = [currentSorting.sortBy];
    }
    doGetLicenseDetails({
      productTaxonomyId: id,
      assigned: selectedAssignedOption.value!,
      pageSize: currentPreferences.pageSize!,
      pageIndex: pageIndexForQuery,
      filters
    });
    setSelectedItems([]);
  };

  const onDeactivateLicenses = async () => {
    refreshTableData(true);
  };

  // Prepare columns
  const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);

  return (
    <Box data-testid="license-details-box">
      {error.hasError && (
        <Alert
          data-testid="license-details-error-alert"
          statusIconAriaLabel="Error"
          type="error"
          header={getString('licenseDetails.table.errorHeader')}
        >
          {error.message}
        </Alert>
      )}
      <Table
        {...collectionProps}
        data-testid="license-details-table"
        header={
          <LicenseDetailsTableHeader
            productName={productName}
            totalResults={totalResults}
            selectedItems={selectedItems!}
            onDeactivateLicenses={onDeactivateLicenses}
            flashBarItemsActions={useFlashBarItemsActions!}
          />
        }
        filter={
          <div className="license-input-container">
            <div className="input-filter">
              <PropertyFilter
                query={currentFilteringQuery}
                onChange={onPropertyFilterChange}
                filteringProperties={FILTERING_PROPERTIES}
                i18nStrings={getPropertyFilterString()}
                hideOperations={true}
              />
            </div>
            <div className="select-filter">
              <Select
                data-testid="select-status"
                selectedOption={selectedAssignedOption}
                onChange={onAssignedChange}
                options={ASSIGNED_OPTIONS}
                selectedAriaLabel="Selected"
              />
            </div>
          </div>
        }
        pagination={
          <Pagination
            currentPageIndex={currentPageIndex.index}
            pagesCount={pagesCount}
            onChange={onPageClick}
            ariaLabels={{
              nextPageLabel: getString('common.pagination.nextPage'),
              previousPageLabel: getString('common.pagination.nextPage'),
              pageLabel: (pageNumber: number) => getString('common.pagination.currentPage', { pageNumber, pagesCount })
            }}
          />
        }
        empty={<EmptyTableBox />}
        stripedRows={currentPreferences.stripedRows}
        preferences={
          <CollectionPreferences
            title={getString('common.preferences.title')}
            confirmLabel={getString('common.preferences.confirmLabel')}
            cancelLabel={getString('common.preferences.cancelLabel')}
            pageSizePreference={{
              title: getString('common.preferences.pageSize.title'),
              options: defaultPagingOptions
            }}
            stripedRowsPreference={{
              label: getString('common.preferences.stripedRows.label'),
              description: getString('common.preferences.stripedRows.description')
            }}
            visibleContentPreference={{
              title: getString('common.preferences.visibleContentPreference.title'),
              options: [
                {
                  label: getString('common.preferences.visibleContentPreference.columns'),
                  options: VISIBLE_CONTENT_COLUMN_OPTIONS
                }
              ]
            }}
            preferences={currentPreferences}
            onConfirm={onPreferencesConfirmHandler}
          />
        }
        columnDefinitions={currentColumnDefinitions}
        loadingText={getString('common.loading')}
        loading={isLoading}
        items={data?.items!}
        onSortingChange={onSortingChange}
        resizableColumns={true}
        selectionType="multi"
        onSelectionChange={handleSelectionChange}
        selectedItems={selectedItems}
        variant="full-page"
        wrapLines
      />
    </Box>
  );
};

export default licenseDetailsTable;
