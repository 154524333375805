import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import LicenseDetailsTable from '../../components/LicenseDetailsTable/LicenseDetailsTable';
import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';

import Paths from "../../common/paths/paths";
import { useLocation } from 'react-router-dom';
import { useFlashBarItems } from 'common/UseFlashBarItems/useFlashBarItems';
import { Flashbar } from '@amzn/awsui-components-react';

const LicenseDetailsPage = () => {
  const flashBarItemsHook = useFlashBarItems();
  
  const location = useLocation();
  let productName = "";
  if (location.state) {
    productName = location.state.value;
  }

  const breadcrumbs = {
    items:
    [
      { text: Paths.Admin.name, href: Paths.uploadLicense.url },
      { text: Paths.manageLicense.name, href: Paths.manageLicense.url },
      { text: productName, href: "#" }
    ]
  }
  
  const [navigationOpen, setNavigationOpen] = useState(false);

  return (
    <AppLayout
      content={<LicenseDetailsTable useFlashBarItemsActions={flashBarItemsHook.actions} />}
      breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
      navigation={<SideNavigationMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
      notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems />}
    />
  );
}

export default LicenseDetailsPage;