import { Alert, HelpPanel } from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import _ from "lodash";
import moment from "moment";
import { Order } from "@amzn/ito-client";
import OrderSidePanelLicenseAttributeBox from "./OrderSidePanelLicenseAttributeBox";
import { OrderStatus } from "configuration/config";

interface OrderDetailsSidePanelProps {
    selectedOrder: Order | null;
}

interface licenseDetails {
    licenseName?: string;
    licenseKey?: string;
    licenseInstructions?: string;
    productDownloadUrl?: string;
}

interface StatusDisplayInfo {
    label: string;
    key: string;
}

const statusMapping: { [key in OrderStatus]?: StatusDisplayInfo } = {
    [OrderStatus.NEW]: { label: "New on", key: "newOn" },
    [OrderStatus.PENDING_APPROVAL]: { label: "Pending approval on", key: "pendingApprovalOn" },
    [OrderStatus.APPROVED]: { label: "Approved on", key: "approvedOn" },
    [OrderStatus.DENIED]: { label: "Denied on", key: "deniedOn" },
    [OrderStatus.PROCESSING]: { label: "Processing on", key: "processingOn" },
    [OrderStatus.COMPLETED]: { label: "Completed on", key: "completedOn" },
    [OrderStatus.CANCELLED]: { label: "Cancelled on", key: "cancelledOn" },
    [OrderStatus.RECALLED]: { label: "Recalled on", key: "recalledOn" },
};

const OrderDetailsSidePanel = ({ selectedOrder }: OrderDetailsSidePanelProps) => {
    const dateFormat = "MM/DD/YYYY";

    const details = selectedOrder?.assets?.at(0)?.details as licenseDetails;
    const itemDetails: any = selectedOrder?.items?.at(0)?.details;
    const placedBy = selectedOrder?.statusHistory?.find((status) => status.status == OrderStatus.NEW)?.by;
    const requestedBy = selectedOrder?.requested?.by!;

    const showPlacedByAlert = placedBy !== requestedBy;

    return (
        <HelpPanel header={<Header>{selectedOrder?.display}</Header>}>
            <SpaceBetween size="m">
                {showPlacedByAlert && (
                    <Alert type="info">{`Order placed by ${placedBy} on behalf of ${requestedBy}`}</Alert>
                )}
                <Box>
                    {selectedOrder &&
                        selectedOrder.statusHistory &&
                        selectedOrder?.statusHistory?.map(
                            // Display all stamps in status history
                            (stamp, id) =>
                                stamp &&
                                stamp.status &&
                                stamp.on &&
                                _.keys(statusMapping).includes(stamp.status!) && (
                                    <OrderSidePanelLicenseAttributeBox
                                        key={`status-stamp-${id}`}
                                        name={statusMapping[stamp.status as OrderStatus]?.key}
                                        label={statusMapping[stamp.status as OrderStatus]?.label}
                                        value={moment(stamp.on).format(dateFormat)}
                                        hoverValue={stamp.on}
                                    />
                                ),
                        )}
                </Box>

                <Box>
                    {itemDetails &&
                        itemDetails.softwareOrderDetails &&
                        (itemDetails.softwareOrderDetails.securityTicketID || itemDetails.softwareOrderDetails.securityTicketId) && (
                            <SpaceBetween size="xs">
                                <Header variant="h3">Security Information</Header>
                                <OrderSidePanelLicenseAttributeBox
                                    name="securityTicket"
                                    label="Security Ticket"
                                    value={(itemDetails.softwareOrderDetails.securityTicketID || itemDetails.softwareOrderDetails.securityTicketId)}
                                    showCopyButton={true}
                                />
                            </SpaceBetween>
                        )}

                    <Header variant="h3">License Information</Header>

                    {details && details.licenseName && (
                        <OrderSidePanelLicenseAttributeBox
                            name="licenseName"
                            label="License name"
                            value={details.licenseName}
                            showCopyButton={true}
                        />
                    )}

                    {details && details.licenseKey && (
                        <OrderSidePanelLicenseAttributeBox
                            name="licenseKey"
                            label="License key"
                            value={details.licenseKey}
                            showCopyButton={true}
                        />
                    )}

                    {details && details.licenseInstructions && (
                        <OrderSidePanelLicenseAttributeBox
                            name="licenseInstructions"
                            label="License instructions"
                            value={details.licenseInstructions}
                            showCopyButton={true}
                            isHtmlContent={true}
                        />
                    )}

                    {details && details.productDownloadUrl && (
                        <OrderSidePanelLicenseAttributeBox
                            name="productDownloadUrl"
                            label="Product Download URL"
                            value={details.productDownloadUrl}
                            showCopyButton={true}
                        />
                    )}
                </Box>
            </SpaceBetween>
        </HelpPanel>
    );
};

export default OrderDetailsSidePanel;
