import './ManageOrdersTable.scss';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  CollectionPreferences,
  Container,
  Grid,
  Header,
  Pagination,
  PaginationProps,
  PropertyFilter,
  PropertyFilterProps,
  Spinner,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { Order } from '@amzn/ito-client';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { ColumnSetting } from 'common/utils/config-models';
import { usePreferences } from 'hooks/usePreferences';
import _ from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { FilesService } from 'services/files-service';

import EmptyTableBox from '../../common/EmptyTableBox/EmptyTableBox';
import { getPropertyFilterString, getString } from '../../common/ui-string-labels/ui-strings-utils';
import {
  buildColumnDefinitions,
  buildFilteringProperties,
  buildSelectOptionsFromStrings,
  buildVisibleContentOptions,
  defaultPagingOptions,
  SortingState} from '../../common/utils';
import { convertTokensToPropertyFilter } from '../../common/utils';
import { useGetOrders } from '../../hooks/orders';
import { CurrentPage } from '../../interfaces';
import { ManageOrdersTableHeader } from './ManageOrdersTableHeader';
import ordersTableConfig from './orders.table-config.json';
import { useDetailsSidePanelResult, useManagerOrdersTableResult } from './useManageOrdersTable';

const PREFERENCES_KEY = 'ManageOrdersPreferences';
const columns: ColumnSetting[] = ordersTableConfig.columns;
const FILTERING_PROPERTIES = buildFilteringProperties(columns);
const VISIBLE_CONTENT_COLUMN_OPTIONS = buildVisibleContentOptions(columns);

const STATUS_OPTIONS = [
  { label: 'Status', value: '' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Denied', value: 'DENIED' },
  { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
  { label: 'Processing', value: 'PROCESSING' }
];

const uploadedOrdersVisibleColumns = [
  'display',
  'requested.on',
  'requested.by',
  'assignedTo',
  'orderReference',
  'status',
  'uploadError'
];

export interface ManageOrdersTableProps {
  useFlashBarItemsActions?: useFlashBarItemsActions;
  useDetailsSidePanel?: useDetailsSidePanelResult;
  useManageOrdersTableHook?: useManagerOrdersTableResult;
  fileId?: string;
  fileName?: string;
}

export const ManageOrdersTable: FunctionComponent<ManageOrdersTableProps> = (props: ManageOrdersTableProps) => {
  // Actions
  const { useFlashBarItemsActions, useDetailsSidePanel, useManageOrdersTableHook } = props;
  const { attributes, actions } = useManageOrdersTableHook!;
  const [loading, setLoading] = useState(false);

  const [currentSorting, setCurrentSorting] = useState<SortingState | undefined>(undefined);

  useEffect(() => {
    showHideOrderDetailsSidePanel(attributes.selectedItems, useDetailsSidePanel);
  }, [attributes.selectedItems]);

  const url = new URL(window.location.href);

  // Preferences
  const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
    preferencesKey: PREFERENCES_KEY,
    refreshData: () => refreshData(true),
    columnsSettings: columns,
    defaultPaging: defaultPagingOptions[0].value
  });

  // Checks for specific order id in the query params
  let orderIdToShow: string | null = null;
  if (url.searchParams.has('orderId')) {
    orderIdToShow = url.searchParams.get('orderId');
  }

  // Filters and pagination
  const [currentFilteringQuery, setFilteringQuery] = useState<PropertyFilterProps.Query>({
    tokens: orderIdToShow
      ? [
          {
            operator: '=',
            value: orderIdToShow,
            propertyKey: 'orderId'
          }
        ]
      : [],
    operation: 'and'
  });
  const [currentPageIndex, setCurrentPageIndex] = useState<CurrentPage>({ index: 1, isPageReset: true });
  const [selectedStatus, setSelectedStatus] = useState(STATUS_OPTIONS[0]);

  const ASSIGNED_TO_OPTIONS: SelectProps.Option[] = [
    { label: 'Assigned To', value: '' },
    ...buildSelectOptionsFromStrings(attributes.procurementMembersSystem)
  ];
  const [selectedAssignee, setSelectedAssignee] = useState(ASSIGNED_TO_OPTIONS[0]);

  // Errors
  const [error, setError] = useState<Error | undefined>(undefined);

  // To get orders from ITO
  const [ordersData, isLoading, doGetOrders] = useGetOrders(
    { pageSize: currentPreferences.pageSize, pageIndex: 1 },
    undefined
  );

  useEffect(() => {
    refreshData(true);
  }, [selectedAssignee, selectedStatus, currentFilteringQuery, currentSorting?.sortBy, currentSorting?.sortOrder]); 

  useEffect(() => {
    if (!currentPageIndex.isPageReset) {
      refreshData(false);
    }
  }, [currentPageIndex.index, currentPageIndex.isPageReset]);

  // Filtering Events
  /* istanbul ignore next */
  const onPropertyFilterChange: NonCancelableEventHandler<PropertyFilterProps.Query> = e => {
    setFilteringQuery(e.detail);
  };

  /* istanbul ignore next */
  const onStatusChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = e => {
    setSelectedStatus({
      label: e.detail.selectedOption.label!,
      value: e.detail.selectedOption.value!
    });
  };

  const onAssignedChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = e => {
    setSelectedAssignee({
      label: e.detail.selectedOption.label!,
      value: e.detail.selectedOption.value!
    });
  };

  // Sorting
  /* istanbul ignore next */
  const onSortingChange: NonCancelableEventHandler<TableProps.SortingState<Order>> = async e => {
    const sortBy = e.detail.sortingColumn.sortingField;
    const sortOrder = e.detail.isDescending ? 'sort.desc' : 'sort.asc';
    setCurrentSorting({ sortBy, sortOrder });
    collectionProps.onSortingChange!(e);
  };

  const { collectionProps } = useCollection(ordersData?.orders ?? [], {
    sorting: {}
  });

  // Pagination
  /* istanbul ignore next */
  const onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail> = e => {
    setCurrentPageIndex({ index: e.detail.currentPageIndex, isPageReset: false });
  };

  /* istanbul ignore next */
  const refreshData = (clearPreviousData: boolean) => {
    let pageIndexForQuery = currentPageIndex.index;
    if (clearPreviousData) {
      pageIndexForQuery = 1;
      setCurrentPageIndex({ index: 1, isPageReset: true });
    }

    const filters: { [key: string]: string[] } = convertTokensToPropertyFilter(currentFilteringQuery.tokens);

    if (selectedStatus.value) {
      filters.status = [selectedStatus.value];
    }
    if (selectedAssignee.value) {
      filters.assignedTo = [selectedAssignee.value];
    }
    if (currentSorting && currentSorting.sortOrder && currentSorting.sortBy) {
      filters[currentSorting.sortOrder] = [currentSorting.sortBy];
    }
    if (currentFilteringQuery && currentFilteringQuery.tokens) {
      for (const token of currentFilteringQuery.tokens) {
        if (!filters[token.propertyKey!]) filters[token.propertyKey!] = [];

        // TODO: pass the actual operator
        filters[token.propertyKey!].push(token.value as string);
      }
    }

    // Add filter by file id
    if (props.fileId) {
      filters['uploadInformation.fileId'] = [props.fileId];
    }
    
    actions.refreshAttributes();
    doGetOrders({
      filters,
      pageSize: currentPreferences.pageSize!,
      pageIndex: pageIndexForQuery
    });
  };

  const onDownloadOrders = async () => {
    setLoading(true);
    await FilesService.instance.downloadOrders();
    refreshData(true);
    setLoading(false);
  };

  // Ready to display
  let pagesCount = 0;
  let totalResults = 0;
  if (ordersData && ordersData.totalResults && ordersData.totalResults > 0) {
    totalResults = ordersData.totalResults;
    pagesCount = Math.ceil(ordersData.totalResults / currentPreferences.pageSize!);
  }

  const uploadErrorColumnIndex = columns.findIndex(c => c.id === 'uploadError');
  if (props.fileId && uploadErrorColumnIndex >= 0) {
    currentPreferences.visibleContent = uploadedOrdersVisibleColumns;
    columns[uploadErrorColumnIndex].visible = 'yes';
  } else {
    if (uploadErrorColumnIndex >= 0) {
      columns[uploadErrorColumnIndex].visible = 'never';
    }
  }

  const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);

  if (loading) {
    return (
      <Container>
        <Header variant="h2" data-testid="download-loading">
          {getString('manageOrders.table.downloadloading')}
        </Header>
        <Spinner size="large" />
      </Container>
    );
  }

  return (
    <Box data-testid="manage-orders-box">
      <Table
        {...collectionProps}
        data-testid="manage-orders-table"
        loading={isLoading}
        onSelectionChange={actions.onSelectionChangeHandler}
        selectedItems={attributes.selectedItems}
        header={
          <ManageOrdersTableHeader
            totalResults={totalResults}
            selectedItems={attributes.selectedItems!}
            refreshData={refreshData}
            useFlashBarItemsActions={useFlashBarItemsActions!}
            procurementMembersSelectOptions={buildSelectOptionsFromStrings(attributes.procurementMembers)}
            onDownloadOrders={onDownloadOrders}
            fileId={props.fileId}
            fileName={props.fileName}
          />
        }
        filter={
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 2 }, { colspan: 2 }]}>
            <PropertyFilter
              expandToViewport
              query={currentFilteringQuery}
              onChange={onPropertyFilterChange}
              filteringProperties={FILTERING_PROPERTIES}
              i18nStrings={getPropertyFilterString()}
              hideOperations={true}
            />
            <Select
              data-testid="select-status"
              selectedOption={selectedStatus}
              onChange={onStatusChange}
              options={STATUS_OPTIONS}
              selectedAriaLabel="Selected"
            />
            <Select
              data-testid="select-assigned"
              selectedOption={selectedAssignee}
              onChange={onAssignedChange}
              options={ASSIGNED_TO_OPTIONS}
              selectedAriaLabel="Selected"
            />
          </Grid>
        }
        pagination={
          <Pagination
            currentPageIndex={currentPageIndex.index}
            pagesCount={pagesCount}
            onChange={onPageClick}
            ariaLabels={{
              nextPageLabel: getString('common.pagination.nextPage'),
              previousPageLabel: getString('common.pagination.nextPage'),
              pageLabel: (pageNumber: number) => getString('common.pagination.currentPage', { pageNumber, pagesCount })
            }}
          />
        }
        onSortingChange={onSortingChange}
        empty={<EmptyTableBox />}
        stripedRows={currentPreferences.stripedRows}
        preferences={
          props.fileId ? undefined : (
            <CollectionPreferences
              title={getString('common.preferences.title')}
              confirmLabel={getString('common.preferences.confirmLabel')}
              cancelLabel={getString('common.preferences.cancelLabel')}
              pageSizePreference={{
                title: getString('common.preferences.pageSize.title'),
                options: defaultPagingOptions
              }}
              stripedRowsPreference={{
                label: getString('common.preferences.stripedRows.label'),
                description: getString('common.preferences.stripedRows.description')
              }}
              visibleContentPreference={{
                title: getString('common.preferences.visibleContentPreference.title'),
                options: [
                  {
                    label: getString('common.preferences.visibleContentPreference.columns'),
                    options: VISIBLE_CONTENT_COLUMN_OPTIONS
                  }
                ]
              }}
              preferences={currentPreferences}
              onConfirm={onPreferencesConfirmHandler}
            />
          )
        }
        columnDefinitions={currentColumnDefinitions}
        loadingText={getString('common.loading')}
        items={ordersData?.orders!}
        resizableColumns={true}
        selectionType="multi"
        variant="full-page"
        wrapLines
      />
    </Box>
  );
};

function showHideOrderDetailsSidePanel(
  selectedItems: Order[],
  useDetailsSidePanel: useDetailsSidePanelResult | undefined
) {
  if (selectedItems && useDetailsSidePanel) {
    if (selectedItems.length === 1) {
      useDetailsSidePanel.actions.showPanel(selectedItems[0]);
    } else if (useDetailsSidePanel.isOpened) {
      useDetailsSidePanel.actions.hidePanel();
    }
  }
}
