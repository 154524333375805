import {
  Box,
  Header,
  Pagination,
  PaginationProps,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react";

import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";

import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { buildColumnDefinitions } from "../../common/utils/table-utils";
import downloadFilesTableConfig from "./downloaded-orders.table-config.json";
import _ from "lodash";

import { useGetOrdersHistoryFiles } from "hooks/orders";
import { useState } from "react";
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";

const COLUMN_DEFINITIONS = buildColumnDefinitions(downloadFilesTableConfig.columns);

// Functional component for displaying downloaded orders table
const DownloadedOrdersTable = () => {
  const PAGE_SIZE: number = 30;

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [filesData, isLoading, doGetBulkDownloadHistory] = useGetOrdersHistoryFiles({ pageSize: PAGE_SIZE, pageIndex: 1 }, undefined);

  // Pagination event handler
  const onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail> = (e) => {
    doGetBulkDownloadHistory({ pageSize: PAGE_SIZE, pageIndex: e.detail.currentPageIndex });
    setCurrentPageIndex(e.detail.currentPageIndex);
  };

  let pagesCount = 0;
  let totalResults = 0;
  
  // Calculating pages count if files data is available
  if (filesData && filesData.totalResults && filesData.totalResults > 0) {
    totalResults = filesData.totalResults;
    pagesCount = Math.ceil(filesData.totalResults / PAGE_SIZE);
  }

  return (
    <Box data-testid="downloaded-orders-box">
      <Table
        data-testid="downloaded-orders-table"
        header={
          <SpaceBetween size="m">
            <Header
              variant="awsui-h1-sticky"
              counter={`(${totalResults})`}
              description={getString("downloadedOrders.table.headerDescription")}
            >
              {getString("downloadedOrders.table.headerTitle")}
            </Header>
          </SpaceBetween>
        }
        pagination={
          <Pagination
            pagesCount={pagesCount}
            currentPageIndex={currentPageIndex}
            onChange={onPageClick}
            ariaLabels={{
              nextPageLabel: getString('common.pagination.nextPage'),
              previousPageLabel: getString('common.pagination.nextPage'),
              pageLabel: (pageNumber: number) => getString('common.pagination.currentPage', { pageNumber, pagesCount })
            }}
          />
        }
        empty={<EmptyTableBox />}
        columnDefinitions={COLUMN_DEFINITIONS}
        loading={isLoading}
        loadingText="Loading files"
        items={filesData?.files!}
        variant="full-page"
      />
    </Box>
  );
}

export default DownloadedOrdersTable;