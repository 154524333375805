/* istanbul ignore file */
/**
 * Debounced implementation for executing a callback for a given wait time
 * Use this implementation if third party libraries doesn't adjust to what some hook requires (i.e. useSearch hook from Stencil)
 * @param fn Callback function to be called
 * @param ms Wait time in milliseconds before the execution of the callback
 * @returns The debounced function to execute and the teardown to clear the timeout
 */
export function debounce<A = any, R = any>(
    fn: (args: A) => Promise<R[]>,
    ms: number,
): [(args: A) => Promise<R[]>, () => void] {
    let timer: NodeJS.Timeout;

    const debouncedFunc = (args: A): Promise<R[]> =>
        new Promise((resolve) => {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                resolve(fn(args));
            }, ms);
        });

    const teardown = () => clearTimeout(timer);

    return [debouncedFunc, teardown];
}
