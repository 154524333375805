import { CATALOGICAL_ENDPOINT } from '../configuration/config';
import { BaseService } from './base-service';
import { StatusCodes } from 'http-status-codes';

export interface CompleteOrderCatalogInformation {
    licenseInstructions?: string;

    price?: number;

    productDownloadUrl?: string
}

export class CatalogicalService extends BaseService {
  /**
   * Singleton instance for catalogical service
   */
  public static instance = new CatalogicalService(CATALOGICAL_ENDPOINT);

  public async getCompleteOrderCatalogInformation(taxonomyId: string): Promise<CompleteOrderCatalogInformation> {
    const result: CompleteOrderCatalogInformation = {};
    const response = await this.fetch(`/catalog/items/${taxonomyId.replace("atn::", "")}`);
    if (response.status === StatusCodes.OK) {
      const jsonResponse = await response.json();
      if (jsonResponse.items?.length > 0)
      {
        const item = jsonResponse.items[0];
        result.licenseInstructions = item.licenseInstructions;
        result.price = item.price;
        result.productDownloadUrl = item.productDownloadUrl;
      }  
    }

    return result;
  }
}
