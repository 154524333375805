import { Box, Button, ColumnLayout, Header, Modal, SpaceBetween, StatusIndicator, Textarea } from '@amzn/awsui-components-react';
import { Order } from '@amzn/ito-client';
import { useEffect, useState } from 'react';
import { getString } from '../../../common/ui-string-labels/ui-strings-utils';
import { KeyLabel } from "../../../common/KeyLabel/KeyLabel";
import { TextAreaField } from "../../../common/FormFields/TextAreaField";
import { useRecallLicenseFromOrder } from '../../../hooks';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { CSRFService } from 'services/csrf-service';

interface licenseDetails {
  licenseName?: string;
  licenseKey?: string;
}

interface RecallLicenseModalProps {
  selectedItem: Order;
  showModal: boolean;
  onDismiss: (shouldRefreshTable?: boolean) => void;
  flashBarItemsActions: useFlashBarItemsActions;
}

export const RecallLicenseModal = (props: RecallLicenseModalProps) => {
  const { selectedItem, flashBarItemsActions, onDismiss } = props;
  const details = selectedItem?.assets?.at(0)?.details as licenseDetails;

  if (!details.licenseKey || !details.licenseName) {
    props.flashBarItemsActions.addErrorFlashBarItem(getString('manageOrders.recallModal.getLicenseError'));
    onDismiss(false);
  }

  const originalLicenseName = details.licenseName ?? "-";
  const originalLicenseKey = details.licenseKey ?? "-";

  const [licenseName, setLicenseName] = useState<string>(originalLicenseName);
  const [licenseKey, setLicenseKey] = useState<string>(originalLicenseKey);
  const [isReviewState, setIsReviewState] = useState<boolean>(false);

  const {recallResponse, isProcessing, error, doRecallOrder} = useRecallLicenseFromOrder();

  /* istanbul ignore next */
  const dismissModal = () => {
    onDismiss(false);
  };

  const onCancelOrBackButton = () => {
    if (isReviewState) { // Back button
      setIsReviewState(false);
    }
    else { // Cancel button
      onDismiss(false);
    }
  };

  const onReviewOrSubmitButton = () => {
    if (isReviewState) { // Submit button
      recallOrder();
    }
    else { // Review button
      setIsReviewState(true);
    }
  };

  /* istanbul ignore next */
  const recallOrder = async () => {
    // Fetch CSRF token
    const csrfService = new CSRFService();
    const token = await csrfService.getToken()

    doRecallOrder({ order: selectedItem, licenseName, licenseKey, csrfToken: token });
  };

  /* istanbul ignore next */
  const onLicenseNameChange = ({ detail }: any) => {
    setLicenseName(detail.value);
  };

  /* istanbul ignore next */
  const onLicenseKeyChange = ({ detail }: any) => {
    setLicenseKey(detail.value);
  };

  const shouldDisableButtons = () => {
    return licenseName.trim() === '' || licenseKey.trim() === '';
  };

  const modalButtons = (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        data-testid="cancel-or-back-recall-action"
        variant="link"
        onClick={onCancelOrBackButton}
        disabled={isProcessing}
      >
        {isReviewState ? getString('manageOrders.recallModal.back') : getString('manageOrders.recallModal.cancel')}
      </Button>
      <Button
        variant="primary"
        loading={isProcessing}
        data-testid="review-or-submit-recall-action"
        onClick={onReviewOrSubmitButton}
        disabled={shouldDisableButtons()}
      >
        {isReviewState ? getString('manageOrders.recallModal.submit') : getString('manageOrders.recallModal.review')}
      </Button>
    </SpaceBetween>
  );

  /* istanbul ignore next */
  useEffect(() => { // Display error or success flashbarx
    if (recallResponse) {
      setIsReviewState(false);
      onDismiss(true);
      if (error?.hasError) {
        flashBarItemsActions.addErrorFlashBarItem(`${getString('manageOrders.recallModal.error')}. ${error.message}`);
      } else {
        flashBarItemsActions.addSuccessFlashBarItem(getString('manageOrders.recallModal.success'));
      }
    }
  }, [recallResponse, error?.hasError]);

  return (
    <Modal
      data-testid="recall-order-modal"
      onDismiss={dismissModal}
      visible={props.showModal}
      header={<Header variant="h2">{"Recall license"}</Header>}
      footer={<Box float="right">{modalButtons}</Box>}
    >
      {/* Review license information */}
      {isReviewState && 
      <SpaceBetween size="s">
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">{`${getString('manageOrders.recallModal.oldLicense')} `}<StatusIndicator type="error"/></Box>
            <KeyLabel label={"License name"} value={originalLicenseName}/>
            <KeyLabel label={"License key"} value={originalLicenseKey}/>
          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">{`${getString('manageOrders.recallModal.newLicense')} `}<StatusIndicator type="success"/></Box>
            <KeyLabel label={"License name"} value={licenseName}/>
            <KeyLabel label={"License key"} value={licenseKey}/>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>}

      {/* Edit license information */}
      {!isReviewState && <SpaceBetween direction="vertical" size="s">
        <TextAreaField label={getString('manageOrders.completeModal.licenseNameLabel')} value={licenseName} onChange={onLicenseNameChange} />
        <TextAreaField label={getString('manageOrders.completeModal.licenseKeyLabel')} value={licenseKey} onChange={onLicenseKeyChange} />
      </SpaceBetween>}
    </Modal>
  );
};