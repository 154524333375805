import { useEffect, useState } from 'react';

import { TableProps } from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

import { Order } from '@amzn/ito-client';
import { OrdersService } from '../../services/orders-service';
import { SYSTEM_FULFILL_NAME } from 'configuration/config';

export enum SelectionState {
  MULTIPLE,
  NONE,
  ONE
}

interface useManagerOrdersTableAttributes {
  procurementMembers: string[];
  procurementMembersSystem: string[];
  selectedItems: Order[];
}

interface useManagerOrdersTableActions {
  onSelectionChangeHandler: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>>;

  /**
   * Refresh the attributes managed by the hook
   */
  refreshAttributes: () => void;

  setSelectedItems: React.Dispatch<React.SetStateAction<Order[]>>;
}

interface useDetailsShowSidePanelFunction {
  (order: Order | null): void;
}

interface useDetailsHideSidePanelFunction {
  (): void;
}

export interface useManagerOrdersTableResult {
  attributes: useManagerOrdersTableAttributes;
  actions: useManagerOrdersTableActions;
}

export interface useDetailsSidePanelActions {
  showPanel: useDetailsShowSidePanelFunction;
  hidePanel: useDetailsHideSidePanelFunction;
}

export interface useDetailsSidePanelResult {
  isOpened: boolean;
  selectedOrder: Order | null;
  actions: useDetailsSidePanelActions;
}

export function useManageOrdersTable(): useManagerOrdersTableResult {
  const [selectedItems, setSelectedItems] = useState<Order[]>([]);
  const [procurementMembers, setProcurementMembers] = useState<string[]>([]);
  const [procurementMembersSystem, setProcurementMembersSystem] = useState<string[]>([]);

  const getProcurementMembers = async () => {
    const members = await OrdersService.instance.getProcurementSoftwareTeamMembers();
    setProcurementMembers(members);
    setProcurementMembersSystem([...members, SYSTEM_FULFILL_NAME]);
  };

  useEffect(() => {
    getProcurementMembers();
  }, []);

  const onSelectionChangeHandler: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>> = e => {
    setSelectedItems!(e.detail.selectedItems);
  };

  const refreshAttributes = () => {
    setSelectedItems([]);
  };

  return {
    attributes: {
      selectedItems,
      procurementMembers,
      procurementMembersSystem
    },
    actions: {
      onSelectionChangeHandler,
      refreshAttributes,
      setSelectedItems
    }
  };
}

export function useDetailsSidePanel(): useDetailsSidePanelResult {
  const [isOpened, setOpened] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  function showPanel(order: Order | null) {
    setOpened(true);
    if (order) {
      setSelectedOrder(order);
    }
  }

  function hidePanel() {
    setOpened(false);
  }

  return { isOpened: isOpened, selectedOrder: selectedOrder, actions: { showPanel, hidePanel } };
}
