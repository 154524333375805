import React from 'react';

import Box from '@amzn/awsui-components-react/polaris/box';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import {
  formatFileSize,
  formatFileLastModified,
  getBaseMetadata
} from '../internal'
import { SelectedFileProps } from '../interfaces'

const SelectedFile: React.FC<SelectedFileProps> = (
  {
    metadata,
    file
  }: SelectedFileProps
) => {

  const baseMetadata = getBaseMetadata(metadata)

  return (
    <Box>
      <SpaceBetween direction='horizontal' size='xs'>
        <Icon variant="success" name="status-positive" />
        <Box>
          {baseMetadata.name && file.name && (
            <Box key={"styles.fileName"}>
              <span title={file.name}>{file.name}</span>
            </Box>
          )}
          <SpaceBetween direction="vertical" size="xxxs">
            {baseMetadata.type && file.type && (
              <Box fontSize="body-s" color="text-body-secondary">
                {file.type}
              </Box>
            )}
            {baseMetadata.size != null && file.size >= 0 && (
              <Box fontSize="body-s" color="text-body-secondary">
                {formatFileSize(file.size, baseMetadata)}
              </Box>
            )}
            {baseMetadata.lastModified && file.lastModified && (
              <Box fontSize="body-s" color="text-body-secondary">
                {formatFileLastModified(file.lastModified, baseMetadata)}
              </Box>
            )}
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </Box>
  )
}

export default SelectedFile;