/* istanbul ignore file */

import { SelectProps } from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { useState } from 'react';
import {
  Order,
  UpdateOrderStatusBatchCommandOutput,
  UpdateOrderStatusCommandOutput
} from '@amzn/ito-client';
import { useUpdateOrdersStatus } from '../../../hooks';
import { CSRFService } from 'services/csrf-service';

const DEFAULT_ASSIGNEE_OPTION: SelectProps.Option = { label: 'Select a team member', value: '' };

export interface useAssignOrderModalActionsResult {
  doAssingOrders: () => void;

  /**
   * Function to be used by the Select on change of Selection
   */
  onAssignedChange: NonCancelableEventHandler<SelectProps.ChangeDetail>;
}

export interface useAssignOrderModalStateResult {
  isLoading: boolean;
  assignee: SelectProps.Option;
  error: Error | null;
  data: UpdateOrderStatusBatchCommandOutput | UpdateOrderStatusCommandOutput | undefined;
}

export interface useAssignOrderModalResult {
  actions: useAssignOrderModalActionsResult;
  state: useAssignOrderModalStateResult;
}

export function useAssignOrderModal(orders: Order[]): useAssignOrderModalResult {
  const { isLoading, data, error, doUpdateOrders } = useUpdateOrdersStatus();
  const [assignee, setAssignee] = useState<SelectProps.Option>(DEFAULT_ASSIGNEE_OPTION);

  const onAssignedChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = e => {
    const selectedAssignedOption = {
      label: e.detail.selectedOption.label!,
      value: e.detail.selectedOption.value!
    };
    setAssignee(selectedAssignedOption);
  };

  const doAssingOrders = () => {
    if (assignee === DEFAULT_ASSIGNEE_OPTION) return;
    // Fetch CSRF token
    const csrfService = new CSRFService();
    csrfService.getToken().then((token: string) => {
      doUpdateOrders(orders, { assignedTo: `${assignee.value}` }, token);
    });
  };

  return {
    actions: {
      doAssingOrders,
      onAssignedChange
    },
    state: {
      data,
      assignee,
      isLoading,
      error
    }
  };
}
