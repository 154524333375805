/* istanbul ignore file */

import { FlashbarProps } from '@amzn/awsui-components-react';
import { getString } from '../ui-string-labels/ui-strings-utils';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface addFlashBarItemFunction {
  (content: string): void;
  (header: string, content: string): void;
}

export interface useFlashBarItemsActions {
  addSuccessFlashBarItem: addFlashBarItemFunction;
  addErrorFlashBarItem: addFlashBarItemFunction;
}

export interface useFlashBarItemsResult {
  flashBarItems: readonly FlashbarProps.MessageDefinition[];
  actions: useFlashBarItemsActions;
}

export function useFlashBarItems(): useFlashBarItemsResult {
  const [flashBarItems, setFlashBarItems] = useState<readonly FlashbarProps.MessageDefinition[]>([]);

  function addSuccessFlashBarItem(content: string): void;
  function addSuccessFlashBarItem(header: string, content: string): void;
  function addSuccessFlashBarItem(header?: string, content?: string) {
    addFlashBarItem('success', content, header);
  }

  function addErrorFlashBarItem(content: string): void;
  function addErrorFlashBarItem(header: string, content: string): void;
  function addErrorFlashBarItem(header?: string, content?: string) {
    addFlashBarItem('error', content, header);
  }

  const addFlashBarItem = (type: FlashbarProps.Type, content?: string, header?: string) => {
    const id = uuidv4();
    setFlashBarItems((prev: readonly FlashbarProps.MessageDefinition[]) => {
      return [
        ...prev,
        {
          type,
          content,
          header,
          dismissible: true,
          dismissLabel: getString('common.flashBar.dismissLabel'),
          onDismiss: () => setFlashBarItems(items => items.filter(item => item.id !== id)),
          id
        }
      ];
    });
  };
  return { flashBarItems, actions: { addSuccessFlashBarItem, addErrorFlashBarItem } };
}
