export interface TableConfig {
  columns: ColumnSetting[]
}

export interface ColumnSetting {
  id: string;
  header: string;
  type?: string;
  props?: any;
  selector: string | string[];
  sortable?: boolean;
  minWidth?: number;
  linkSetting?: LinkItemSetting;
  operators?: string[];
  defaultOperator?: string;
  visibleByDefault?: boolean;
  visible: VisibleOption | string;
}

export enum VisibleOption {
  "never" = "never",
  "always" = "always",
  "yes" = "yes",
  "no" = "no"
}
export interface LinkItemSetting {
  url: string;
  idSelector: string;
}

