import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { UserPermissions } from "../../services/user-permissions";
import { getString } from "../ui-string-labels/ui-strings-utils";

const CustomHeader = () => {
  return (
      <TopNavigation
      data-testid="top-navigation-bar"
      identity={{
        href: "#",
        title: getString("common.topNav.title"),
      }}
      utilities={[
        {
          type: "button",
          text: UserPermissions.instance.username,
          iconName: "user-profile"
        }
      ]}
      i18nStrings={{
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
      }}
    />
  );
}

export default CustomHeader;
