import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomHeader from "../common/CustomHeader/CustomHeader";

import UploadLicensePage from "../views/UploadLicensePage"
import ManageLicensePage from "../views/ManageLicensePage";
import LicenseDetailsPage from "../views/LicenseDetailsPage";
import ManageOrdersPage from "../views/ManageOrdersPage";
import DownloadedOrdersPage from "views/DownloadedOrders";

import Paths from "../common/paths/paths";
import UploadOrdersPage from "views/UploadOrdersPage";
import OrdersUploadedPage from "views/OrdersUploadedPage";

const AdminRoutes = () => (
    <BrowserRouter>
        <CustomHeader />
        <Routes>
            <Route path={Paths.uploadLicense.url} element={<UploadLicensePage />} />
            <Route path={Paths.manageLicense.url} element={<ManageLicensePage />} />
            <Route path={Paths.manageOrders.url} element={<ManageOrdersPage />} />
            <Route path={Paths.licenseDetails.url} element={<LicenseDetailsPage/>} />
            <Route path={Paths.uploadOrders.url} element={<UploadOrdersPage />} />
            <Route path={Paths.ordersUploaded.url} element={<OrdersUploadedPage />} />
            <Route path={Paths.ordersDownloaded.url} element={<DownloadedOrdersPage />} />
            <Route path="/*" element={<ManageOrdersPage />} />
        </Routes>
    </BrowserRouter>
);

export default AdminRoutes;