import Box from "@amzn/awsui-components-react/polaris/box";
import { getString } from "../ui-string-labels/ui-strings-utils";

const EmptyTableBox = () => {
  return (
    <Box textAlign="center" color="inherit" data-testid="empty-table">
    <b>{getString("common.table.emptyTableTitle")}</b>
    <Box
      padding={{ bottom: "s" }}
      variant="div"
      color="inherit"
    >
      {getString("common.table.emptyTableMessage")}
    </Box>
  </Box>
  );
}

export default EmptyTableBox;
