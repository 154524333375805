import { API_BASE_URL } from '../configuration/config';
import { BaseService } from './base-service';
import { StatusCodes } from 'http-status-codes';

export class OrdersService extends BaseService {
  /**
   * Singleton instance for orders service
   */
  public static instance = new OrdersService(API_BASE_URL);

  public async getProcurementSoftwareTeamMembers(): Promise<string[]> {
    const response = await this.fetch('/get-global-software-procurement-team');
    if (response.status !== StatusCodes.OK) {
      throw new Error(
        `An error occurred when trying to obtain get the information on Procurement Software Team members: ${JSON.stringify(
          response
        )}`
      );
    }

    const jsonResponse = await response.json();
    return jsonResponse;
  }
}
