import get from "lodash/get";

import uiStrings from "./ui-strings.json";
import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter";

export const getString = (id: string, obj?: { [key: string]: any }): string => {
    let outputString = get(uiStrings, id, "");
    if (obj != null) {
        for (const key in obj) {
            const formatKey = "{{" + key + "}}";
            outputString = outputString.replace(formatKey, obj[key]);
        }
    }
    return outputString;
};

export const getPropertyFilterString = (): PropertyFilterProps.I18nStrings => {
    return {
        ...uiStrings.propertyFiltering,
        removeTokenButtonAriaLabel: token => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: text => `Use: "${text}"`
    }
};
